import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form, Input, Select, Image, DatePicker } from 'antd';
import dayjs from 'dayjs';

import http from '../../../lib/http';
import * as apis from '../../../lib/account';

import styles from './index.module.css';

const formDate = (time) => dayjs(time).format('YYYY-MM-DD HH:mm:ss');
function Com(props) {
  const { onSave, selectCompany } = props;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const formValue = {
      ...values,
      validity_period_at: formDate(values.validity_period_at),
      platform_role: Number(values.platform_role),
      status: Number(values.status),
    };

    if (selectCompany?.id) {
      formValue.id = selectCompany?.id;
    }

    onSave(formValue);
  };

  useEffect(() => {
    if (selectCompany) {
      form.setFieldsValue({
        ...selectCompany,
        platform_role: String(selectCompany?.platform_role),
        status: String(selectCompany?.status),
        validity_period_at: dayjs(selectCompany?.validity_period_at),
      });
    } else {
      form.setFieldsValue({
        name: '',
        contacts: '',
        contacts_phone: '',
        platform_role: '',
        validity_period_at: '',
        permission_id: '',
      });
    }
  }, [selectCompany]);

  return (
    <div className="createUser-content">
      <Form
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="商户名称"
          name="name"
          rules={[
            {
              required: true,
              message: '商户名称必填!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="商户联系人"
          name="contacts"
          rules={[
            {
              required: true,
              message: '商户联系人必填!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="联系人电话"
          name="contacts_phone"
          rules={[
            {
              required: true,
              message: '联系人电话必填!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="平台角色"
          name="platform_role"
          rules={[
            {
              required: true,
              message: '平台角色必填!',
            },
          ]}
        >
          <Select>
            <Select.Option value="1">VIP商户</Select.Option>
            <Select.Option value="2">A级面料商</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="有效截止日期"
          name="validity_period_at"
          rules={[
            {
              required: true,
              message: '有效截止日期必填!',
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              hideDisabledOptions: true,
            }}
          />
        </Form.Item>

        {/* // 状态 1.正常；2.过期 3 禁用 */}
        <Form.Item
          label="商户状态"
          name="status"
          rules={[
            {
              required: true,
              message: '商户状态必填!',
            },
          ]}
        >
          <Select>
            <Select.Option value="1">正常</Select.Option>
            <Select.Option value="2">过期</Select.Option>
            <Select.Option value="3">禁用</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Com;
