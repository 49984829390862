import { Button, Input, message, Tabs, Table, Modal, Spin, Form, InputNumber, Space,Collapse,theme, Checkbox, Radio } from 'antd'
import { FolderOpenOutlined } from '@ant-design/icons';
import React,{ useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../../components/context'
import md5 from 'md5'
import http from '../../lib/http'
import * as apis from '../../lib/api'
import Client3D from "../../lib/three";
import { cloneDeep } from 'lodash';
import Upload, {handleValue} from '../../components/upload'
import NormalTexture, { maps } from './normal_texture'
import { useLocation, useNavigate } from "react-router-dom";

const { Panel } = Collapse;

const Index = () => {
    const { token } = theme.useToken();
    const l = useLocation()
    const n = useNavigate()
    const [loading, setLoading] = useState(false)
    const [threeClient, setThreeClient] = useState(null)
    const [meshs, setMeshs] = useState([])
    const [curMeshIndex, setCurMeshIndex] = useState()
    const [lookupMesh, setLookupMesh] = useState(false)
    const canvasRef = useRef()
    const normalTextureRef = useRef()
 

    useEffect(()=>{
        const params = new URLSearchParams(l.search)
        const id = params.get("id")
        setLoading(true)
        http.get(`${apis.MODEL_DETAIL}?id=${id}`).then(data => {
            if(data.items){
                data.items = JSON.parse(data.items)
            }
            console.log(data)

            //启动3D场景
            let ops = {
                canvasDom: canvasRef?.current,
                canvasWidth: 375,
                canvasHeight: 667
            }
            //初始化3DClient
            let client = new Client3D(ops)
            setThreeClient(client)
  
            //启动3D场景
            client.start()
            client.loadModel(process.env.REACT_APP_OSS_URL+data.path, (p) => {
                console.log(p)
            }).then(r => {
                console.log("rrrrrrr",r.getObjects())
                const newMesh = []
                for(let i=0;i<r?.getObjects()?.children?.length;i++){
                    let item = r?.getObjects()?.children[i]
                    let f = data.items?.find(j => j.code === item.name)
                    if(f){
                        newMesh.push(f)
                    }else{
                        newMesh.push({
                            code: item.name,
                            txt: item.name,
                            img: "",
                            camera: null,
                            items: []
                        })
                    }
                }
                setMeshs(newMesh)
                setTimeout(() => {
                    collapseChange(newMesh[0].code, client, newMesh)
                },300)
  
                setLoading(false)
            })            
        })
        
        return () => {
            threeClient?.destroy()
        }
    },[])


    const columns = [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name',
          render: (v,r,dx) => (
            <Input placeholder="请输入" allowClear value={v} onChange={e => {
                let cpMeshs = cloneDeep(meshs)
                cpMeshs[curMeshIndex].items[dx].name = e.target.value
                setMeshs(cpMeshs)
            }} />
          )
        },
        {
            title: '封面',
            dataIndex: 'cover',
            key: 'cover',
            render: (v,r,dx) => {
                let cpMeshs = cloneDeep(meshs)
                return <Upload value={handleValue(v)} onChange={e => {
                    if(e && e.length !== 0){
                        cpMeshs[curMeshIndex].items[dx].cover = e[0].url
                    }else{
                        cpMeshs[curMeshIndex].items[dx].cover = ""
                    }

                    setMeshs(cpMeshs)
                }} />
            }
        },
        {
          title: '部件',
          dataIndex: 'path',
          key: 'path',
          render: (v,r,dx) => (
            <Upload accept=".obj" value={handleValue(v)} onChange={e => {
                let cpMeshs = cloneDeep(meshs)
                if(e && e.length !== 0){
                    cpMeshs[curMeshIndex].items[dx].path = e[0].url
                }else{
                    cpMeshs[curMeshIndex].items[dx].path = ""
                }
                setMeshs(cpMeshs)
            }} />
          )    
        },
        {
            title: '设计文件',
            dataIndex: 'design_file_path',
            key: 'design_file_path',
            render: (v,r,dx) => (
              <Upload size={1024*200} value={handleValue(v)} accept="*" onChange={e => {
                  let cpMeshs = cloneDeep(meshs)
                  if(e && e.length !== 0){
                    cpMeshs[curMeshIndex].items[dx].design_file_path = e[0].url
                  }else{
                    cpMeshs[curMeshIndex].items[dx].design_file_path = ""
                  }
                  setMeshs(cpMeshs)
              }} />
            )    
        },
        {
            title: '操作',
            dataIndex: 'a',
            key: 'a',
            render: (v,r,dx) => (
                <>
                    <Space>
                        <Button loading={loading} style={{padding: 0}} type="link" onClick={async ()=> {
                            console.log(r)
                            setLoading(true)
                            try {
                                threeClient.replaceGeometry(meshs[curMeshIndex].code, r.path)
                            } catch (error) {
                                message.error("加载部件失败")
                            }
                            setLoading(false)
                        }}>预览</Button>
                        <Button loading={loading} style={{padding: 0}} type="link" danger onClick={()=> {
                            Modal.confirm({
                                title: "提示",
                                content: <p>确定删除吗?</p>,
                                onOk: async () => {
                                    let cpMeshs = cloneDeep(meshs)
                                    cpMeshs[curMeshIndex].items.splice(dx,1)
                                    setMeshs(cpMeshs)
                                }
                            })
                        }}>删除</Button>
                    </Space>
                </>
            )
        }
    ];

    const save = async () => {
        console.log(meshs)
        let success = true
        jump:
        for(let i=0;i<meshs.length;i++){
            let item = meshs[i]
            for(let j=0;j<item.items.length;j++){
                let jitem = item.items[j]
                if(jitem.cover === "" || jitem.name === "" || jitem.path === "" || jitem.design_file_path === ""){
                    message.error(`请补全部件${item.txt}的空缺项`)
                    success = false
                    break jump
                }
                jitem.md5 = md5(jitem.name)
            }
        }
        if(!success){
            return
        }
        setLoading(true)
        try {
            const params = new URLSearchParams(l.search)
            const id = params.get("id")
            await http.post(apis.MODEL_ITEMS_UPDATE, {
                items: JSON.stringify(meshs),
                id: parseInt(id)
            })
            message.success("保存成功")
            n("/model/list")
        } catch (error) {
            message.error("保存失败")
        }
        setLoading(false)
    }

    const headTxt = (v) => {
        let sy = {
            width: 100,
            display: "inline-block",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        }
        return <div>
            <span style={sy}>{v.txt}</span>
            <span style={{width: 20,height: 20,display: "inline-block"}}></span>
        </div>
    }

    const collapseChange = (k, c = threeClient, ms = meshs) => {
        let fi = ms?.findIndex(i => i.code === k)
        setCurMeshIndex(fi)
        c.activate(k)
    } 

    return (
        <Spin spinning={loading}>
            <div style={{
                height: "600px",
                overflowX: 'auto',
                overflowY:"hidden",
                position: "relative"
            }}>
                <div>
                    <div style={{display: "flex"}}>
                        <div style={{padding: 8}}>
                            <Space>
                                <h4>部位列表({meshs?.length})</h4>
                                <Checkbox checked={lookupMesh} onChange={(e) => {
                                    setLookupMesh(e.target.checked)
                                    const codes = []
                                    for(let i=0;i<meshs.length;i++){
                                        if(meshs[i].renderFabric){
                                            codes.push(meshs[i].code)
                                        }
                                    }
                                    if(e.target.checked){
                                        threeClient.setMaterialColor(codes, "red")
                                    }else{
                                        threeClient.setMaterialColor(codes, "#ffffff")
                                    }
                                }}>已激活版片</Checkbox>
                                <Checkbox onChange={(e) => {
                                    let cpMesh = cloneDeep(meshs)
                                    const codes = []
                                    for(let i=0;i<meshs.length;i++){
                                        if(e.target.checked){
                                            cpMesh[i].renderFabric = true
                                            if(lookupMesh){
                                                //threeClient.setMaterialColor([meshs[i].code], "#ffffff")
                                                threeClient.setMaterialColor([meshs[i].code], "red")
                                            }else{
                                                
                                            }
                                        }else{
                                            cpMesh[i].renderFabric = false
                                            if(lookupMesh){
                                                threeClient.setMaterialColor([meshs[i].code], "#ffffff")
                                                //threeClient.setMaterialColor([meshs[i].code], "red")
                                            }else{
                                                
                                            }
                                        }
                                    }
                                    setMeshs(cpMesh)
                                }}>全选</Checkbox>
                            </Space>
                            <Collapse
                                accordion
                                style={{ background: token.colorBgContainer,width: 250, height: 600, overflow:"auto", paddingBottom: 100 }}
                                activeKey={meshs[curMeshIndex]?.code}
                                onChange={(k) => {
                                    if(!k){
                                        return
                                    }
                                    collapseChange(k)
                                }}
                            >
                                {
                                    meshs?.map((v,k) => (
                                        <Panel header={headTxt(v)} key={v.code}>
                                            <Space>
                                                <Button onClick={() => {
                                                    let cpMeshs = cloneDeep(meshs)
                                                    cpMeshs[k].items.push({
                                                        id: new Date().getTime(),
                                                        name: "",
                                                        cover: "",
                                                        path: "",
                                                        design_file_path: ""
                                                    })
                                                    setMeshs(cpMeshs)
                                                }} type="link" style={{padding: 0}}>添加部件</Button>
                                                <Radio name='isModel' onChange={(v) => {
                                                    let cpMeshs = cloneDeep(meshs)
                                                    cpMeshs[k].isModel = v
                                                    setMeshs(cpMeshs)
                                                }} type="link" style={{padding: 0}}>模特</Radio>
                                                {/* {
                                                    v.camera && <Button loading={loading} type="link" style={{padding: 0}} onClick={()=>{
                                                        threeClient.setCamera(v.camera.x,v.camera.y,v.camera.z)
                                                    }}>复位</Button>
                                                } */}
                                                <Checkbox checked={!!v.renderFabric} onChange={(e)=>{
                                                    let cpMeshs = cloneDeep(meshs)
                                                        cpMeshs[k].renderFabric = e.target.checked
                                                        setMeshs(cpMeshs)
                                                        const codes = []
                                                        for(let i=0;i<cpMeshs.length;i++){
                                                            if(lookupMesh){
                                                                threeClient.setMaterialColor([cpMeshs[i].code], "#ffffff")
                                                            }
                                                            if(cpMeshs[i].renderFabric){
                                                                codes.push(cpMeshs[i].code)
                                                            }
                                                        }
                                                        if(lookupMesh){
                                                            threeClient.setMaterialColor(codes, "red")
                                                        }
                                                        
                                                }}>激活版片</Checkbox>
                                            </Space>
                                            <div style={{height: 12}}></div>
                                            <div>
                                                <Input placeholder="部位名" value={v.txt} onChange={(e)=>{
                                                    let cpMeshs = cloneDeep(meshs)
                                                    cpMeshs[k].txt = e.target.value
                                                    setMeshs(cpMeshs)
                                                }} />
                                            </div>
                                            <div style={{height: 12}}></div>
                                            <div>裁片图：</div>
                                            <div style={{height: 12}}></div>
                                            <div>
                                                <Upload value={handleValue(v.img)} onChange={e => {
                                                    let cpMeshs = cloneDeep(meshs)
                                                    if(e && e.length !== 0){
                                                        cpMeshs[k].img = e[0].url
                                                    }else{
                                                        cpMeshs[k].img = ""
                                                    }
                                                    setMeshs(cpMeshs)
                                                }} />
                                            </div>
                                        </Panel>
                                    ))
                                }
                            </Collapse>
                        </div>
                        <div style={{width: 12}}></div>
                        <div style={{
                            width: 375,
                            height: 667, 
                            background: "linear-gradient(180deg, rgba(238,238,238,0) 0%, #F8F8F8 100%)",
                            border: "solid 1px #f5f5f5",
                            borderTop: "none",
                            cursor: "pointer",
                            position: "relative"
                        }}>
                            <canvas onPointerUp={() => {
                                if(threeClient.outlinePass.selectedObjects.length !== 0){
                                    collapseChange(threeClient.outlinePass.selectedObjects[0].name)
                                }
                            }} style={{display: "block",touchAction: "none"}} ref={canvasRef}></canvas>
                        </div>
                        <div style={{width: 12}}></div>
                        <div style={{padding: 8, minWidth: 600,overflow:"auto"}}>
                            <h4>可替换部件({meshs[curMeshIndex]?.items?.length || 0})</h4>
                            <Table 
                                scroll={{y: 667 - 55}} 
                                pagination={false} 
                                rowKey={"id"} 
                                dataSource={meshs[curMeshIndex]?.items} 
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{textAlign: "center", marginTop: 12 }}>
                    <Space>
                        <Button type="primary" onClick={save}>保存配置</Button>
                    </Space>
                </div>
        </Spin>
    )
}

export default Index