import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './router/index';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import ZH from 'antd/locale/zh_CN'
import { StyleProvider } from '@ant-design/cssinjs';
import 'cropperjs/dist/cropper.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider locale={ZH} theme={{
    token: {
      colorPrimary: '#189F92',
    }
  }}>
    <StyleProvider hashPriority="high">
    <App />
    </StyleProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
