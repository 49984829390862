import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Select,
  Input,
  Row,
  Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Prefix from '../../../components/prefix';
import CreateCompany from './createCompany';

import http from '../../../lib/http';
import * as apis from '../../../lib/account';

import styles from './index.module.css';

function Com() {
  const [loading, setLoading] = useState(false);
  const [selectCompany, setSelectCompany] = useState(null);
  const [operaType, setOperaType] = useState('add');
  const [showCompanyCreatePop, setShowCompanyCreatePop] = useState(false);
  let search = { name: '', contacts: '', contacts_phone: '', phone: '' };
  const [dataSource, setDataSource] = useState({
    page: 1,
    page_size: 10,
    total: 0,
    list: [],
  });

  const columns = useMemo(() => {
    return [
      {
        title: '商户名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '商户联系人',
        dataIndex: 'contacts',
        key: 'contacts',
      },
      {
        title: '联系人电话',
        dataIndex: 'contacts_phone',
        key: 'contacts_phone',
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        width: '100px',
        render: (res) => (
          <span>
            {String(res) === '1'
              ? '正常'
              : String(res) === '2'
              ? '过期'
              : '禁用'}
          </span>
        ),
      },
      {
        title: '平台角色',
        dataIndex: 'platform_role',
        render: (res) => (
          <span>
            {String(res) === '1'
              ? 'VIP商户'
              : String(res) === '2'
              ? 'A级面料商'
              : '平台'}
          </span>
        ),
      },
      {
        title: '截止日期',
        dataIndex: 'validity_period_at',
        key: 'validity_period_at',
      },
      {
        title: '创建日期',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: '更新时间',
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
      {
        title: '操作',
        key: 'action',
        width: '150px',
        render: (_, record) => (
          <Space
            size="middle"
            onClick={() => {
              updateCompanyClick(record);
            }}
          >
            <a>编辑</a>
          </Space>
        ),
      },
    ];
  }, []);

  // 添加账号
  const addCompanyClick = () => {
    setSelectCompany(null);
    setOperaType('add');
    setShowCompanyCreatePop(true);
  };

  // 编辑账号
  const updateCompanyClick = (company) => {
    setSelectCompany(company);
    setOperaType('update');
    setShowCompanyCreatePop(true);
  };

  // 表格页数改变的时候
  const pageSearch = async (page, page_size) => {
    getCompany({ page: page, page_size: page_size });
  };

  const onSearch = (value) => {
    search = value;
    getCompany();
  };

  // 加载数据
  const getCompany = async (pageConfig = { page: 1, page_size: 10 }) => {
    setLoading(true);
    const data = await http.post(apis.companyList, {
      ...pageConfig,
      ...search,
    });
    if (data?.list) {
      data.list.map((item) => {
        item.key = item.id;
      });
      setDataSource(data);
      setLoading(false);
    }
  };

  // 编辑或者添加账号
  const companyOpera = async (data) => {
    if (operaType === 'add') {
      await http.post(apis.companyCreate, data);
      getCompany();
      setShowCompanyCreatePop(false);
    } else {
      await http.post(apis.companyUpdate, data);
      getCompany();
      setShowCompanyCreatePop(false);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div className={styles.companyQuery}>
      <div className={styles.companyTableDesc}>
        <Form onFinish={onSearch}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="name">
                <Prefix label="商户名称">
                  <Input allowClear placeholder="请输入商户名称" />
                </Prefix>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="platform_role">
                <Prefix label="平台角色">
                  <Select allowClear placeholder="请选择">
                    <Select.Option value={1}>VIP商户</Select.Option>
                    <Select.Option value={2}>A级面料商</Select.Option>
                    {/* <Select.Option value={3}>平台</Select.Option> */}
                  </Select>
                </Prefix>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status">
                <Prefix label="商户状态">
                  <Select allowClear placeholder="请选择">
                    <Select.Option value={1}>正常</Select.Option>
                    <Select.Option value={2}>过期</Select.Option>
                    <Select.Option value={3}>禁用</Select.Option>
                  </Select>
                </Prefix>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Space>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    查询
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={addCompanyClick}
                  >
                    新增商户
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Table
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: pageSearch,
          total: dataSource?.total,
          current: dataSource?.page,
          pageSize: dataSource?.page_size,
        }}
        loading={loading}
        style={{ minWidth: '650px' }}
        scroll={{ y: 500 }}
        columns={columns}
        dataSource={dataSource.list}
      />

      {showCompanyCreatePop ? (
        <Modal
          title={`${operaType === 'add' ? '新增' : '编辑'} 商户`}
          footer={null}
          open={showCompanyCreatePop}
          onCancel={() => setShowCompanyCreatePop(false)}
        >
          <CreateCompany selectCompany={selectCompany} onSave={companyOpera} />
        </Modal>
      ) : null}
    </div>
  );
}

export default Com;
