import Login from "../pages/login";
import ProductCategory from "../pages/product/category";
import Product from "../pages/product";
import Mconfig from "../pages/mconfig/index";
import MItemConfig from "../pages/m_item_config";
import Pattern from "../pages/pattern";
import CustomFabric from "../pages/custom/fabric";
import CustomFabricCategory from "../pages/custom/fabric/category";
import CustomImgFabric from "../pages/custom/imgfabric";
import CustomImgFabricCategory from "../pages/custom/imgfabric/category";
import Fabric from "../pages/fabric";
import FabricCategory from "../pages/fabric/category";
import AFabric from "../pages/assistfabric";
import AFabricCategory from "../pages/assistfabric/category";
import PcPattern from "../pages/pc_pattern";
import PcPatternCategory from "../pages/pc_pattern/category";
import Design from "../pages/design";
import DesignCategory from "../pages/design/category";
import VipDesignCategory from "../pages/vip_design/category";
import VipDesignList from "../pages/vip_design";
import Scene from "../pages/scene";
import SceneCategory from "../pages/scene/category";
import Model from "../pages/model";
import ModelCategory from "../pages/model/category";
import Order from "../pages/order";
import OrderExportList from "../pages/order/export_list";
import Font from "../pages/font";
import Home from "../pages/home";
import IndexConfig from "../pages/index_config";
import PatternCategory from "../pages/pattern/category";
import JobList from "../pages/account/job";
import CompanyList from "../pages/company/list";
import MasterAccountList from "../pages/company/masterAccount";
import PcChartlet from "../pages/pc_chartlet";
import PcChartletCategory from "../pages/pc_chartlet/category";
/** 走秀动画审批 */
import FashionShowApprove from "../pages/fashionShow/approve";
/** 走秀动画上架 */
import FashionShowUpframe from "../pages/fashionShow/upframe";
import {
  AntDesignOutlined,
  SketchOutlined,
  GlobalOutlined,
  AppstoreAddOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  HomeOutlined,
  SettingOutlined,
  DollarOutlined,
  BorderOuterOutlined,
} from "@ant-design/icons";
import { Breadcrumb } from "antd";

const style = {
  margin: "16px 0",
};

const Config = {
  "/": {
    icon: <HomeOutlined />,
    component: <Home />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>主页</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/product": {
    icon: <AppstoreAddOutlined />,
  },
  "/product/category": {
    component: <ProductCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>产品管理</Breadcrumb.Item>
        <Breadcrumb.Item>产品分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/product/list": {
    component: <Product />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>产品管理</Breadcrumb.Item>
        <Breadcrumb.Item>产品列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/product/mconfig": {
    component: <Mconfig />,
    layout: true,
    hideMenu: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>产品管理</Breadcrumb.Item>
        <Breadcrumb.Item>产品列表</Breadcrumb.Item>
        <Breadcrumb.Item>模型配置</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pattern": {
    icon: <FileImageOutlined />,
  },
  "/pattern/category": {
    component: <PatternCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>定位花管理</Breadcrumb.Item>
        <Breadcrumb.Item>定位花分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pattern/list": {
    component: <Pattern />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>定位花管理</Breadcrumb.Item>
        <Breadcrumb.Item>定位花列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/font": {
    icon: <FontSizeOutlined />,
  },
  "/font/list": {
    component: <Font />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>字体管理</Breadcrumb.Item>
        <Breadcrumb.Item>字体列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/order": {
    icon: <DollarOutlined />,
  },
  "/order/list": {
    component: <Order />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>订单管理</Breadcrumb.Item>
        <Breadcrumb.Item>订单列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/export/list": {
    component: <OrderExportList />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>订单管理</Breadcrumb.Item>
        <Breadcrumb.Item>导出列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/config": {
    icon: <SettingOutlined />,
  },
  "/config/app": {
    component: <IndexConfig />,
    name: "应用配置",
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>配置管理</Breadcrumb.Item>
        <Breadcrumb.Item>应用配置</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/login": {
    component: <Login />,
    layout: false,
  },
  "/customimgfabric": {
    icon: <BorderOuterOutlined />,
  },
  "/customimgfabric/fabric/list": {
    component: <CustomFabric />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>自定义面料</Breadcrumb.Item>
        <Breadcrumb.Item>底胚管理</Breadcrumb.Item>
        <Breadcrumb.Item>底胚列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/customimgfabric/fabric/category": {
    component: <CustomFabricCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>自定义面料</Breadcrumb.Item>
        <Breadcrumb.Item>底胚管理</Breadcrumb.Item>
        <Breadcrumb.Item>底胚分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/customimgfabric/imgfabric/list": {
    component: <CustomImgFabric />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>自定义面料</Breadcrumb.Item>
        <Breadcrumb.Item>花型管理</Breadcrumb.Item>
        <Breadcrumb.Item>花型分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/customimgfabric/imgfabric/category": {
    component: <CustomImgFabricCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>自定义面料</Breadcrumb.Item>
        <Breadcrumb.Item>花型管理</Breadcrumb.Item>
        <Breadcrumb.Item>花型列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/imgfabric": {
    icon: <BorderOuterOutlined />,
  },
  "/imgfabric/list": {
    component: <Fabric />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>成品面料</Breadcrumb.Item>
        <Breadcrumb.Item>面料列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/imgfabric/category": {
    component: <FabricCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>成品面料</Breadcrumb.Item>
        <Breadcrumb.Item>面料分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/assistfabric": {
    icon: <BorderOuterOutlined />,
  },
  "/assistfabric/list": {
    component: <AFabric />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>辅料管理</Breadcrumb.Item>
        <Breadcrumb.Item>辅料列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/assistfabric/category": {
    component: <AFabricCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>辅料管理</Breadcrumb.Item>
        <Breadcrumb.Item>辅料分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pc/pattern": {
    icon: <FileImageOutlined />,
  },
  "/pc/pattern/list": {
    component: <PcPattern />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>定位花管理</Breadcrumb.Item>
        <Breadcrumb.Item>定位花列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pc/pattern/category": {
    component: <PcPatternCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>定位花管理</Breadcrumb.Item>
        <Breadcrumb.Item>定位花分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/scene": {
    icon: <GlobalOutlined />,
  },
  "/scene/list": {
    component: <Scene />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>背景管理</Breadcrumb.Item>
        <Breadcrumb.Item>背景列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/scene/category": {
    component: <SceneCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>背景管理</Breadcrumb.Item>
        <Breadcrumb.Item>背景分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/model": {
    icon: <SketchOutlined />,
  },
  "/model/list": {
    component: <Model />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>模型管理</Breadcrumb.Item>
        <Breadcrumb.Item>模型列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/model/category": {
    component: <ModelCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>模型管理</Breadcrumb.Item>
        <Breadcrumb.Item>模型分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/model/m_item_config": {
    component: <MItemConfig />,
    layout: true,
    hideMenu: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>模型管理</Breadcrumb.Item>
        <Breadcrumb.Item>模型列表</Breadcrumb.Item>
        <Breadcrumb.Item>部件配置</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/design": {
    icon: <AntDesignOutlined />,
  },
  "/design/list": {
    component: <Design />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>设计管理</Breadcrumb.Item>
        <Breadcrumb.Item>设计列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/design/category": {
    component: <DesignCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>设计管理</Breadcrumb.Item>
        <Breadcrumb.Item>设计分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/vdesign": {
    icon: <AntDesignOutlined />,
  },
  "/vdesign/category": {
    component: <VipDesignCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>设计管理</Breadcrumb.Item>
        <Breadcrumb.Item>设计分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/vdesign/list": {
    component: <VipDesignList />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>设计管理</Breadcrumb.Item>
        <Breadcrumb.Item>设计列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/account": {
    icon: <BorderOuterOutlined />,
  },
  "/account/job": {
    component: <JobList />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>账号管理</Breadcrumb.Item>
        <Breadcrumb.Item>职位列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/company": {
    icon: <BorderOuterOutlined />,
  },
  "/company/list": {
    component: <CompanyList />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>商户列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/company/master_account": {
    component: <MasterAccountList />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>商户管理</Breadcrumb.Item>
        <Breadcrumb.Item>商户主账号列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pc/chartlet": {
    icon: <FileImageOutlined />,
  },
  "/pc/chartlet/category": {
    component: <PcChartletCategory />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>贴图管理</Breadcrumb.Item>
        <Breadcrumb.Item>贴图分类</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/pc/chartlet/list": {
    component: <PcChartlet />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>贴图管理</Breadcrumb.Item>
        <Breadcrumb.Item>贴图列表</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/fashionShow/approve": {
    component: <FashionShowApprove />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>走秀动画管理</Breadcrumb.Item>
        <Breadcrumb.Item>走秀动画审批</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
  "/fashionShow/upframe": {
    component: <FashionShowUpframe />,
    layout: true,
    breadcrumb: (
      <Breadcrumb style={style}>
        <Breadcrumb.Item>走秀动画管理</Breadcrumb.Item>
        <Breadcrumb.Item>走秀动画上架</Breadcrumb.Item>
      </Breadcrumb>
    ),
  },
};

export default Config;
