import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, Modal, Space, Switch, Table, Input } from 'antd';
import CategoryModal from './cmodel';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash'
import * as apis from '../../../lib/api'
import * as tool from '../../../lib/tool'
import http from '../../../lib/http'

import { judgePermission } from '../../../utils/index'

const LevelMap = {
  '1': '一级分类',
  '2': '二级分类',
  '3': '三级分类',
  '4': '四级分类',
};


var swapItems = function (arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0]
  return arr
}

const upData = (arr, index) => {
  if (arr.length > 1 && index !== 0) {
    return swapItems(arr, index, index - 1)
  }
}

const downData = (arr, index) => {
  if (arr.length > 1 && index !== (arr.length - 1)) {
    return swapItems(arr, index, index + 1)
  }
}

const dataMap = {}

export default function CategoryConfig() {
  const [isVisible, setIsVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [visible, setVisible] = useState(false)
  const [currentBizId, setCurrentBizId] = useState("")

  const moveUp = async (record, index) => {
    let adata = upData(dataMap[record.super_id], index)
    let sortList = []
    for (let i = 0; i < adata.length; i++) {
      let item = adata[i]
      sortList.push({ id: item.id, sort: i })
    }
    setLoading(true)
    try {
      await http.post(apis.MODEL_CATEGORY_SORT_UPDATE, sortList)
      await loadData()
    } catch (error) {
      message.error(error)
    }
    setLoading(false)
  }

  const moveDown = async (record, index) => {
    let adata = downData(dataMap[record.super_id], index)
    let sortList = []
    for (let i = 0; i < adata.length; i++) {
      let item = adata[i]
      sortList.push({ id: item.id, sort: i })
    }
    setLoading(true)
    try {
      await http.post(apis.MODEL_CATEGORY_SORT_UPDATE, sortList)
      await loadData()
    } catch (error) {
      message.error(error)
    }
    setLoading(false)
  }

  const columns = useMemo(() => {
    const clo = [
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '分类状态',
        dataIndex: 'status',
        key: 'status',
        render: (v, record) => {
          return <Switch
            defaultChecked={v === 1}
            checked={v === 1}
            onChange={() => onStatusChange(!(v === 1), record.id)}
          />
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (_, record, index) => {
          return (
            <Space>
              {judgePermission('modelCategoryEdit') ? <Button type="link" onClick={() => onAction('edit', record)}>
                编辑
              </Button> : null}
              {
                judgePermission('modelCategoryAddChildrenCategory') && record.level < 3 && <Button type="link" onClick={() => onAction('addSub', record)}>
                  新增子分类
                </Button>
              }
              {
                judgePermission('modelCategoryMoveUp') ? <Button type="link" disabled={record.upDisable} onClick={() => { moveUp(record, record.index) }}>
                  上移
                </Button> : null
              }
              {
                judgePermission('modelCategoryDown') ? <Button type="link" disabled={record.downDisable} onClick={() => { moveDown(record, record.index) }}>
                  下移
                </Button> : null
              }
            </Space>
          );
        },
      },
    ];

    const findStatus = clo.findIndex(item => item.title === '分类状态')

    if (findStatus !== -1 && !judgePermission('modelCategoryStatus')) {
      clo.splice(findStatus, 1)
    }

    return clo
  }, [])

  useEffect(() => {
    setLoading(true)
    loadData().then(d => {
      setLoading(false)
    })

  }, []);

  //递归重置上移，下移的按钮禁用
  const recursiveDisableMoveBtn = (children) => {
    children[0].upDisable = true
    children[children.length - 1].downDisable = true
    for (let i = 0; i < children.length; i++) {
      children[i].index = i
      dataMap[`${children[i].super_id}`] = children
      if (children[i].children && children[i].children.length != 0) {
        recursiveDisableMoveBtn(children[i].children)
      }
    }
  }

  const loadData = async (search = { is_pub: 2 }) => {
    try {
      let ret = await http.post(apis.MODEL_CATEGORY_LIST, search)

      ret = tool.handleLevelData(ret)
      console.log(ret)
      if (ret && ret.length != 0) {
        recursiveDisableMoveBtn(ret)
      }
      setData(ret)
    } catch (error) {
      console.log("errorerror", error)
      message.error(error)
    }
  }

  const updateCategory = async (info) => {
    console.log(info)
    const payload = {
      id: info.id,
      name: info.name
    };
    // const [code, msg] = await updateCategoryRequest(payload);
    // if (code === 0) {
    //   message.success('更新成功');
    //   return;
    // }
    // message.error(msg);
    // console.log(msg);
  };

  const addCategory = async (info) => {
    console.log("info", info)
    const payload = {
      level: info.level,
      name: info.name,
      super_id: info.level === 1 ? 0 : info.id
    };
    try {
      await http.post(apis.MODEL_CATEGORY_CREATE, payload)
      await loadData()
      message.success('添加成功');
    } catch (error) {
      message.error(error);
    }
  };

  const onStatusChange = async (status, id) => {
    setLoading(true)
    const payload = {
      status: status ? 1 : 2,
      id,
    };
    await http.post(apis.MODEL_CATEGORY_SHOW_UPDATE, payload)
    await loadData()
    setLoading(false)
  };

  //更新名字
  const updateName = async () => {
    if (!name) {
      message.error("名称不能为空")
      return
    }
    setLoading(true)
    try {
      await http.post(apis.MODEL_CATEGORY_UPDATE, { id: currentBizId, name: name })
      await loadData()
      setVisible(false)
    } catch (error) {
      message.error(error)
    }
    setLoading(false)
  }

  const onAction = (actionType, record) => {
    console.log('新增分类', record);
    switch (actionType) {
      case 'edit':
        setName(record?.name)
        setCurrentBizId(record?.id)
        setVisible(true)

        break;
      case 'addSub':
        setModalInfo({
          ...record,
          title: levelStr(record.level + 1 + ''),
          actionType: 'add',
          level: record.level + 1,
        });
        setIsVisible(true);
        break;
      case 'addTop':
        setModalInfo({
          title: levelStr('1'),
          actionType: 'add',
          parentId: '0',
          level: 1,
        });
        setIsVisible(true);
        break;
      default:
        break;
    }
  };

  const levelStr = (level) => {
    return '新增' + LevelMap[level];
  };

  const onModalOk = async (info) => {
    setIsVisible(false);

    switch (info.actionType) {
      case 'add':
        setLoading(true)
        await addCategory(info);
        setLoading(false)
        break;
      case 'edit':
        setLoading(true)
        await updateCategory(info);
        setLoading(false)
        break;
      default:
        break;
    }
  };

  const onModalCancel = () => {
    setIsVisible(false);
  };


  return (
    <div className="category-container">
      {
        judgePermission('modelCategoryAddOneLevelCategory') ? <div style={{ marginBottom: 12 }}>

          <Button
            onClick={() => onAction('addTop')}
            type="primary"
            icon={<PlusOutlined />}
          >
            {' '}
            新增一级分类
          </Button>
        </div> : null
      }

      <Table
        rowKey={`id`}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 335px)'
        }}
      />
      <CategoryModal
        status={1}
        propIsVisible={isVisible}
        propModalInfo={modalInfo}
        propOnOk={onModalOk}
        propOnCancel={onModalCancel}
      />
      <Modal
        open={visible}
        title={`更新分类名称`}
        onOk={updateName}
        okText="确认"
        cancelText="取消"
        width={400}
        onCancel={() => { setVisible(false) }}
      >
        <Space>
          <div>分类名称</div>
          <Input
            style={{ width: '280px' }}
            value={name}
            onChange={(e) => { setName(e.target.value) }}
            maxLength={50}
          />
        </Space>
      </Modal>
    </div>
  );
}
