import * as apis from '../../lib/api';
import http from '../../lib/http';
import * as THREE from 'three';
import { Modal, Image, message, Spin, Button } from 'antd';
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import { useContext, useState, useRef, useEffect } from 'react';
import { globalContext } from '../context';
import md5 from 'md5';
import { cloneDeep } from 'lodash';
import Cropper from 'cropperjs';
import * as tool from '../../lib/tool';

let cropper;
let mapLoaderCache;
let object3Dchilds;
export const handleValue = (path) => {
  const arr = [];
  if (!path) {
    return arr;
  }
  if (typeof path === 'object') {
    for (let i = 0; i < path.length; i++) {
      let v = path[i];
      arr.push({
        url: v?.url,
        status: 'done',
        thumbUrl: process.env.REACT_APP_OSS_URL + v?.url,
      });
    }
    return arr;
  }
  return [
    {
      url: path,
      status: 'done',
      thumbUrl: process.env.REACT_APP_OSS_URL + path,
    },
  ];
};

const isImg = (path) => {
  if (
    path.indexOf('.jpg') !== -1 ||
    path.indexOf('.jpeg') !== -1 ||
    path.indexOf('.png') !== -1
  ) {
    return true;
  }
};

const Index = (props) => {
  const [visible, setVisible] = useState(false);
  const [cropVisible, setCropVisible] = useState(-1);
  const {
    size = 1024 * 40,
    maxCount = 1,
    value = [],
    disabled = false,
    showCrop = false,
    multiple = false,
    lightness = 0,
    saturation = 0,
    colorTemperature = 0,
    contrast = 0,
    setToning,
    disabledUpload=false
  } = props;
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { ossClient } = useContext(globalContext);
  const fileInput = useRef();
  const cropImg = useRef();
  let cpvalue = cloneDeep(value);

  const uploadButton = (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{props.txt || '请上传'}</div>
    </div>
  );

  const beforeUpload = async (e) => {
    if(disabledUpload){
       message.warning(`请先上传模型文件！`);
       e.target.value = null;
       return false;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file.size / 1024 > size) {
        message.error(`文件大小不能超过${parseInt(size / 1024)}M`);
        return Promise.reject(`文件大小不能超过${parseInt(size / 1024)}M`);
      }
      setLoading(true);
      const uuid = await http.get(apis.UUID);
      const suffix = file.name.slice(file.name.lastIndexOf('.'));
      const filename = md5(uuid) + suffix;
      console.log('oss url ', process.env.REACT_APP_OSS_BUCKET_DIR + (props.childPath ? props.childPath : '') + '/' + filename)
      const data = await ossClient.put(
        process.env.REACT_APP_OSS_BUCKET_DIR + (props.childPath ? props.childPath : '') + '/' + filename,
        file
      );
      setLoading(false);

      if (cpvalue.length >= maxCount) {
        message.error(`最多只能上传${maxCount}个文件`);
        return false;
      }
      cpvalue.push({
        uid: file.uid,
        name: file.name,
        url: data.name,
        thumbUrl: process.env.REACT_APP_OSS_URL + data.name,
        status: 'done',
        percent: 100,
      });
      props?.onChange(cpvalue);
      await tool.sleep(500);
    }
    if (setToning) {
      renderImage();
    }

    e.target.value = '';
    return Promise.resolve();
  };

  const renderImage = () => {
    // debugger;
    const images = document.querySelectorAll('.changColor');
    for (let i = 0; i < images.length; i++) {
      images[0].style.filter = `saturate(${saturation + 100}%) brightness(${lightness + 100}%) contrast(${contrast + 100}%) hue-rotate(${colorTemperature}deg)`
    }
  };

  const handlePreview = async (file, isEdit) => {
    if (isImg(file.thumbUrl)) {
      if (setToning && isEdit) {
        const images = document.querySelectorAll('.changColor')[0];
        let filterValue = window.getComputedStyle(images).getPropertyValue('filter');
        var canvas = document.createElement('canvas');
        const img = new window.Image();
        img.src = file.thumbUrl;
        img.setAttribute("crossOrigin", 'Anonymous')
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext('2d');
          ctx.filter = filterValue;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const dataURL = canvas.toDataURL('image/png');
          setPreviewUrl(dataURL);
        }
      } else {
        setPreviewUrl(file.thumbUrl);
      }
      setTimeout(() => {
        setVisible(true);
      }, 500)
      return;
    }
    window.location.href = file.thumbUrl;
  };

  const doCrop = (dx) => {
    setCropVisible(dx);
    setTimeout(() => {
      cropper = new Cropper(document.getElementById(`img_${dx}`), {
        // 宽高比
        // aspectRatio: 16 / 9,
        rotatable: true,
        scalable: true,
        crop(event) {
          // console.log(event.detail.x);
          // console.log(event.detail.y);
          // console.log(event.detail.width);
          // console.log(event.detail.height);
          // console.log(event.detail.rotate);
          // console.log(event.detail.scaleX);
          // console.log(event.detail.scaleY);
        },
      });
      // console.log(cropper);
    }, 500);
  };
  useEffect(() => {
    if (setToning) {
      renderImage();
    }
    // 父组件传来的值发生了变化，执行相应的操作
  }, [
    props.contrast,
    props.colorTemperature,
    props.saturation,
    props.lightness,
  ]);

  return (
    <>
      <input
        accept={props.accept || '.jpg,.png,.jpeg'}
        onChange={beforeUpload}
        ref={fileInput}
        type="file"
        multiple={multiple}
        style={{ display: 'none' }}
      />
      <Spin spinning={loading}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
          }}
        >
          {value?.map((v, dx) => (
            <div
              key={dx}
              className="diy-upload"
              style={{
                width: 86,
                height: 86,
                position: 'relative',
                marginRight: 3,
                marginBottom: 3,
              }}
            >
              <div
                className="diy-upload-item"
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  background: 'rgba(0,0,0,0.8)',
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="link"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    handlePreview(v);
                  }}
                ></Button>
                <Button
                  type="link"
                  disabled={disabled}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    let cpvalue = cloneDeep(value);
                    cpvalue.splice(dx, 1);
                    props?.onChange(cpvalue);
                  }}
                ></Button>
                {showCrop && (
                  <Button
                    type="link"
                    disabled={disabled}
                    icon={<ScissorOutlined />}
                    onClick={() => {
                      doCrop(dx);
                    }}
                  ></Button>
                )}
              </div>
              {isImg(v.thumbUrl) ? (
                <img
                  src={v.thumbUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px #999 solid',
                  }}
                >
                  <FileOutlined />
                </div>
              )}
              <Modal
                title="裁剪"
                forceRender
                open={cropVisible === dx}
                confirmLoading={loading}
                onOk={() => {
                  setLoading(true);
                  cropper.getCroppedCanvas().toBlob(async (blob) => {
                    const uuid = await http.get(apis.UUID);
                    const filename = md5(uuid) + '.png';
                    const data = await ossClient.put(
                      process.env.REACT_APP_OSS_BUCKET_DIR + '/' + filename,
                      blob
                    );
                    let cpvalue = cloneDeep(value);
                    cpvalue[dx].url = data.name;
                    cpvalue[dx].thumbUrl =
                      process.env.REACT_APP_OSS_URL + data.name;
                    setCropVisible(-1);
                    cropper.destroy();
                    props?.onChange(cpvalue);
                    setLoading(false);
                  });
                }}
                onCancel={() => {
                  cropper.destroy();
                  setCropVisible(-1);
                }}
              >
                <div>
                  <img
                    id={`img_${dx}`}
                    src={v.thumbUrl}
                    style={{ display: 'block', maxWidth: '100%' }}
                  />
                </div>
              </Modal>
            </div>
          ))}
          {value?.length !== maxCount && (
            <div
              onClick={() => {
                fileInput?.current?.click();
              }}
              style={{
                width: 84,
                height: 84,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px #999 dashed',
                cursor: 'pointer',
              }}
            >
              {uploadButton}
            </div>
          )}

          {value?.map((v, dx) => (
            <div
              key={dx}
              className="diy-upload"
              style={{
                width: '200px',
                height: '200px',
                position: 'absolute',
                left: '100px',
                top: '-60px',
                display: props.setToning ? 'block' : 'none'
              }}
            >
              <div
                className="diy-upload-item"
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  background: 'rgba(0,0,0,0.8)',
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 9
                }}
              >
                <Button
                  type="link"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    handlePreview(v, 'isEdit');
                  }}
                ></Button>

              </div>
              {isImg(v.thumbUrl) ? (
                <img
                  src={v.thumbUrl}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  className="changColor"
                />
              ) : (
                <div></div>
              )}

            </div>
          ))}
        </div>
      </Spin>
      {/* <Upload
                listType="picture-card"
                maxCount={maxCount}
                fileList={value}
                
                action={false}
                onRemove={(e) => {
                    let cpvalue = cloneDeep(value)
                    let index = cpvalue.findIndex(v => {
                        return e.thumbUrl.indexOf(v.url) !== -1
                    })
                    if (index !== -1){
                        cpvalue.splice(index,1)
                    }
                    props?.onChange(cpvalue)      
                }}
                onPreview={handlePreview}
            >
                
            </Upload> */}

      <div style={{ display: 'none' }}>
        <Image
          width={200}
          src={previewUrl}
          preview={{
            visible,
            src: previewUrl,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </div>
    </>
  );
};

export default Index;
