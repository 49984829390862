import { useState, useEffect, useContext } from 'react';
import { Card, Col, Divider, Row, Statistic } from 'antd';
import CountUp from 'react-countup';
import { Line } from '@ant-design/plots';
import { useNavigate } from "react-router-dom";
import { globalContext } from '../../components/context'

const Index = () => {
    const [data, setData] = useState([]);
    const c = useContext(globalContext)
    const navigate = useNavigate()


    useEffect(() => {
        const f = c?.roleData?.permission_info?.find(v => v.super_id !== 0)
        if(f){
            navigate(f.path)
            return
        }
    }, [c?.menuItemData]);

    const config = {
        data,
        xField: 'year',
        yField: 'gdp',
        seriesField: 'name',
        yAxis: {
            label: {
            formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
            },
        },
        legend: {
            position: 'top',
        },
        smooth: true,
        // @TODO 后续会换一种动画方式
        animation: {
            appear: {
            animation: 'path-in',
            duration: 5000,
            },
        },
    };

    return (
        <div>
            
        </div>
    )
}

export default Index