import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import menuConfig from './config';
import * as utils from '../utils';
import OSS from 'ali-oss';
import { globalContext as GlobalContext } from '../components/context';
import { useState, useEffect } from 'react';
import http from '../lib/http';
import * as apis from '../lib/api';
import * as account from '../lib/account';
import { btnPermissionData } from '../utils/index';
import { powerList, accountMenu, playMenu, fashionShowAll_menu, fashionShow_approve_menu } from '../utils/powerList';

const handelPageTree = (page) => {
  const pageTree = [];

  const sortTree = (parentTree, children) => {
    if (!children.length) {
      return;
    }

    let resetChildren = [];
    children.forEach((childrenItem) => {
      if (
        childrenItem.path.indexOf(parentTree.path) === 0 &&
        childrenItem.path.split('/').length ===
          parentTree.path.split('/').length + 1
      ) {
        if (parentTree.children) {
          parentTree.children.push(childrenItem);
        } else {
          parentTree.children = [childrenItem];
        }
      } else {
        resetChildren.push(childrenItem);
      }
    });

    parentTree.children?.forEach((parentTreeItem) => {
      sortTree(parentTreeItem, resetChildren);
    });
  };
  page.sort((a, b) => a.path.split('/').length - b.path.split('/').length);

  let resetPage = [];
  page.forEach((item) => {
    if (item.path.split('/').length == 2) {
      pageTree.push(item);
    } else {
      resetPage.push(item);
    }
  });

  pageTree.forEach((parentTreeItem) => {
    sortTree(parentTreeItem, resetPage);
  });

  return pageTree;
};

const handleMenu = (menu) => {
  menu.forEach((item) => {
    if (item.path && item.name) {
      item.key = item.path;
      item.label = item.name;
    }

    if (item.children) {
      handleMenu(item.children);
    }
  });
};

const handleArr = (menu) => {
  const arr = [];
  const deepFun = (menuDeep) => {
    menuDeep.forEach((item) => {
      if (item.path?.split('/').length > 2 && item.name) {
        arr.push({
          key: item.path,
          path: item.path,
          label: item.name,
          name: item.name,
        });
      }

      if (item.children) {
        deepFun(item.children);
      }
    });
  };

  deepFun(menu);
  return arr;
};

const R = () => {
  const [data, setData] = useState();
  const [ossClient, setOssClient] = useState();
  const [menuItemData, setMenuItemData] = useState([]);
  const [platform_role, setPlatform_role] = useState('');
  const onLoad = async () => {
    const dataretdata = await http.get(apis.USER_INFO);
    const companyInfo = await http.post(account.companyInfo);
    setPlatform_role(String(companyInfo.platform_role));
    const { is_master, permission_info } = dataretdata;
    if (dataretdata && is_master !== 1 && !permission_info?.content) {
      return '抱歉，该账号没有权限';
    }
    let content = {};
    if (is_master === 1) {
      // 主账号
      content.menu = [...powerList, accountMenu];
      if (String(companyInfo.platform_role) !== '3') {
        const index = content.menu.findIndex((e) => e.name == '商户管理');
        if (index !== -1) {
          content.menu.splice(index, 1);
        }
        content.menu = content.menu.concat(fashionShowAll_menu)
      } else {
        content.menu = content.menu.concat(fashionShowAll_menu)
      }
      if (String(companyInfo.platform_role) === '5') {
        content.menu = playMenu;
      }
      content.permission = ['is_master'];
    } else {
      const { menu, permission } = JSON.parse(permission_info.content);
      content.menu = handelPageTree(menu);
      content.permission = permission;
    }

    const { menu, permission } = content;

    const dataret = {
      ...dataretdata,
      permission_info: handleArr(menu),
    };
    handleMenu(menu);
    setMenuItemData(menu);
    setData(dataret);
    // 页面按钮权限
    btnPermissionData.splice(0, btnPermissionData.length);
    permission?.forEach((item) => {
      btnPermissionData.push(item);
    });

    const ossdata = await http.get(apis.ALI_STS);
    const store = new OSS({
      region: process.env.REACT_APP_OSS_REGION,
      bucket: process.env.REACT_APP_OSS_BUCKET,
      accessKeyId: ossdata?.access_key_id,
      accessKeySecret: ossdata?.access_key_secret,
      stsToken: ossdata?.security_token,
      timeout: 60000 * 2,
      refreshSTSToken: async () => {
        const info = await http.get(apis.ALI_STS);
        return {
          accessKeyId: info?.access_key_id,
          accessKeySecret: info?.access_key_secret,
          stsToken: info?.security_token,
        };
      },
      refreshSTSTokenInterval: 300000,
    });
    setOssClient(store);
  };
  useEffect(() => {
    if (window.location.pathname === '/login') {
      return;
    }
    onLoad();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        ossClient,
        roleData: data,
        menuItemData,
        onLoad,
        platform_role,
      }}
    >
      <BrowserRouter>
        <Routes>
          {
            // 渲染不是在layout布局组件里的组件
            data?.permission_info?.map(
              (v, k) =>
                menuConfig[v.path] &&
                !menuConfig[v.path].layout && (
                  <Route
                    key={k}
                    path={v.path}
                    element={menuConfig[v.path].component}
                  />
                )
            )
          }
          <Route
            key={'login'}
            path="login"
            element={menuConfig['/login'].component}
          />
          <Route
            path="/*"
            element={
              <Layout>
                <Routes>
                  {
                    // 渲染不是在layout布局组件里的组件
                    data?.permission_info?.map(
                      (v, k) =>
                        menuConfig[v.path] &&
                        menuConfig[v.path].layout && (
                          <Route
                            key={k}
                            path={v.path}
                            element={menuConfig[v.path].component}
                          />
                        )
                    )
                  }
                  <Route
                    key={'home'}
                    path="/"
                    element={menuConfig['/'].component}
                  />
                  {platform_role === '5' ? (
                    <Route
                      key={'/product/mconfig'}
                      path="/product/mconfig"
                      element={menuConfig['/product/mconfig'].component}
                    />
                  ) : null}
                </Routes>
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
};

export default R;
