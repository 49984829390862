import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Space,
  Table,
  Modal,
  Select,
  Switch,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import CreateAccount from './createAccount';
import Prefix from '../../../components/prefix';

import http from '../../../lib/http';
import * as apis from '../../../lib/account';

import styles from './index.module.css';

function Com() {
  let search = { name: '', phone: '', status: '', company_name: '' };

  const [loading, setLoading] = useState(false);
  const [selectAccount, setSelectAccount] = useState(null);
  const [operaType, setOperaType] = useState('add');
  const [showAccountCreatePop, setShowAccountCreatePop] = useState(false);
  const [dataSource, setDataSource] = useState({
    page: 1,
    page_size: 10,
    total: 0,
    list: [],
  });

  const columns = useMemo(() => {
    return [
      {
        title: '用户姓名',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '用户帐号',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '用户头像',
        dataIndex: 'avatar',
        key: 'avatar',
        render: (res, r) => (res ? <img src={res} /> : ''),
      },

      {
        title: '用户状态',
        dataIndex: 'status',
        key: 'status',
        render: (res, r) => <Switch checked={res === 1} disabled />,
      },
      {
        title: '商户名称',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      {
        title: '商户编号',
        dataIndex: 'company_id',
        key: 'company_id',
      },

      // {
      //   title: "操作",
      //   key: "action",
      //   width: "150px",
      //   render: (_, record) => (
      //     <Space size="middle" onClick={() => {
      //       updateAccountClick(record)
      //     }}>
      //       <a>编辑</a>
      //     </Space>
      //   ),
      // },
    ];
  }, []);

  // 添加账号
  const addAccountClick = () => {
    setSelectAccount(null);
    setOperaType('add');
    setShowAccountCreatePop(true);
  };

  // 编辑账号
  const updateAccountClick = (account) => {
    setSelectAccount(account);
    setOperaType('update');
    setShowAccountCreatePop(true);
  };

  // 表格页数改变的时候
  const pageSearch = async (page, page_size) => {
    getAccount({ page: page, page_size: page_size });
  };

  const onSearch = (value) => {
    search = value;
    getAccount();
  };

  // 加载数据
  const getAccount = async (pageConfig = { page: 1, page_size: 10 }) => {
    setLoading(true);
    const data = await http.post(apis.masterAccountList, {
      ...pageConfig,
      ...search,
    });
    if (data?.list) {
      data.list.map((item) => {
        item.key = item.id;
      });
      setDataSource(data);
      setLoading(false);
    }
  };

  // 编辑或者添加账号
  const companyOpera = async (data) => {
    if (operaType === 'add') {
      await http.post(apis.masterAccountCreate, data);
      getAccount();
      setShowAccountCreatePop(false);
    } else {
      // await http.post(apis.companyUpdate, data);
      // getAccount();
      // setShowAccountCreatePop(false)
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <div className={styles.accountQuery}>
      <div className={styles.accountTableDesc}>
        <Form onFinish={onSearch}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="company_name">
                <Prefix label="商户名称">
                  <Input allowClear placeholder="请输入商户名称" />
                </Prefix>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="platform_role">
                <Prefix label="平台角色">
                  <Select allowClear placeholder="请选择">
                    <Select.Option value={1}>VIP商户</Select.Option>
                    <Select.Option value={2}>A级面料商</Select.Option>
                    {/* <Select.Option value={3}>平台</Select.Option> */}
                  </Select>
                </Prefix>
                {/* <Input allowClear/> */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="company_status">
                <Prefix label="商户状态">
                  <Select allowClear placeholder="请选择">
                    <Select.Option value={1}>正常</Select.Option>
                    <Select.Option value={2}>过期</Select.Option>
                    <Select.Option value={3}>禁用</Select.Option>
                  </Select>
                </Prefix>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <Space>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    查询
                  </Button>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={addAccountClick}
                  >
                    新增账号
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* <div>
          <Button type="primary" htmlType="submit" onClick={addAccountClick}>
            新增账号
          </Button>
        </div> */}
      </div>

      <Table
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: pageSearch,
          total: dataSource?.total,
          current: dataSource?.page,
          pageSize: dataSource?.page_size,
        }}
        loading={loading}
        style={{ minWidth: '650px' }}
        scroll={{ y: 500 }}
        columns={columns}
        dataSource={dataSource.list}
      />

      {showAccountCreatePop ? (
        <Modal
          title={`${operaType === 'add' ? '新增' : '编辑'} 账号`}
          footer={null}
          open={showAccountCreatePop}
          onCancel={() => setShowAccountCreatePop(false)}
        >
          <CreateAccount selectAccount={selectAccount} onSave={companyOpera} />
        </Modal>
      ) : null}
    </div>
  );
}

export default Com;
