import { cloneDeep } from 'lodash'
import Upload, {handleValue}  from '../../components/upload'

const Index = (props) => {
    const { value = [],disabledUpload=false } = props

    const setDada = (mapName, url) => {
        let cpValue = cloneDeep(value)
        let fdx = cpValue.findIndex(v => v.map === mapName)
        if(fdx === -1){
            cpValue.push({map: mapName, url})
        }else{
            if(!url){
                // cpValue[fdx] = {map: mapName, url}
                cpValue.splice(fdx, 1)  
            }else{
                cpValue[fdx] = {map: mapName, url}
            }
        }
        if(props.onChange){
            props.onChange(cpValue)
        }
    }

    const getMap = (mapName) => {
        let fdx = value.findIndex(v => v.map === mapName)
        if(fdx === -1){
            return handleValue("")
        }else{
            return handleValue(value[fdx].url)
        }
    }

    return (
        <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between", marginTop: 6}}>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>颜色贴图</div> 
               <Upload disabledUpload={disabledUpload} value={getMap("map")} onChange={(e) => {
                    if(e && e.length !== 0){
                        setDada("map", e[0].url)
                    }else{
                        setDada("map", "")
                    }
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>法线贴图</div> 
               <Upload value={getMap("normalMap")} disabledUpload={disabledUpload} onChange={(e) => {
                    setDada("normalMap",(e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>高光贴图</div> 
               <Upload disabledUpload={disabledUpload} value={getMap("specularMap")} onChange={(e) => {
                    setDada("specularMap", (e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>环境贴图</div> 
               <Upload disabledUpload={disabledUpload} value={getMap("aoMap")} onChange={(e) => {
                    setDada("aoMap", (e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>置换贴图</div> 
               <Upload disabledUpload={disabledUpload} value={getMap("displacementMap")} onChange={(e) => {
                    setDada("displacementMap", (e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>粗糙度贴图</div> 
               <Upload disabledUpload={disabledUpload} value={getMap("roughnessMap")} onChange={(e) => {
                    setDada("roughnessMap", (e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            <div style={{marginBottom: 18}}>
               <div style={{marginBottom: 8}}>金属光泽度贴图</div> 
               <Upload disabledUpload={disabledUpload}  value={getMap("metalnessMap")} onChange={(e) => {
                    setDada("metalnessMap", (e && e.length !== 0 ) ? e[0].url:"")
               }} />
            </div>
            {/* <Form.Item name="map" label="颜色贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="normalMap" label="法线贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="specularMap" label="高光贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="aoMap" label="环境贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="displacementMap" label="置换贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="roughnessMap" label="粗糙度贴图">
                <Upload />
            </Form.Item>
            <Form.Item name="metalnessMap" label="金属光泽度贴图">
                <Upload />
            </Form.Item> */}
        </div>
    )
}

export default Index