import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Button, Form, Input, Select, Image, Checkbox } from 'antd';

import Upload from '../../../../components/upload';

import http from '../../../../lib/http';
import * as apis from '../../../../lib/account';

import { globalContext } from '../../../../components/context';
import styles from './index.module.css';
import { uniqueId } from '../../../../utils';

function Com(props) {
  const { onSave, selectUser } = props;
  const c = useContext(globalContext);
  const [form] = Form.useForm();
  const [job, setJob] = useState([]);
  const [computerTag, setComputerTag] = useState('open');
  const [imageUrl, setImageUrl] = useState();
  const [platform_role, setPlatform_role] = useState('');

  const onFinish = (values) => {
    onSave({
      ...values,
      computer_tag: computerTag || 'open',
      avatar: imageUrl,
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getAvatar = (data) => {
    if (data[0]?.status === 'done') {
      const url = data[0].thumbUrl;
      setImageUrl(url);
    }
  };

  const getJob = async () => {
    const data = await http.post(apis.roleList, { page: 1, pageSize: 1000 });
    if (data?.list) {
      setJob(
        data.list.map((item) => {
          item.key = item.id;
          return item;
        })
      );
    }
  };

  const getCode = (val) => {
    if (val.target.checked) {
      setComputerTag(uniqueId);
    } else {
      setComputerTag('open');
    }
  };

  const isEdit = useMemo(() => selectUser !== null);
  useEffect(() => {
    setPlatform_role(c.platform_role);
    if (selectUser) {
      setPlatform_role(String(selectUser.account_type));
      form.setFieldsValue({
        password: '',
        phone: selectUser.phone,
        role: selectUser.role,
        name: selectUser.name,
        status: String(selectUser.status),
        account_type: String(selectUser.account_type),
      });
      setComputerTag(selectUser.computer_tag);
      setImageUrl(selectUser.avatar);
    } else {
      form.setFieldsValue({
        password: '',
        phone: '',
        role: '',
        name: '',
        account_type: '',
        status: '1',
      });
      setComputerTag('open');
    }
    getJob();
  }, [selectUser]);

  return (
    <div className="createUser-content">
      <Form
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="用户昵称"
          name="name"
          rules={[
            {
              required: true,
              message: '用户名称必填!',
            },
          ]}
        >
          <Input disabled={isEdit} />
        </Form.Item>
        <Form.Item
          label="用户名"
          name="phone"
          rules={[
            {
              required: true,
              message: '用户名必填!',
            },
          ]}
        >
          <Input disabled={isEdit} />
        </Form.Item>
        {!isEdit ? (
          <Form.Item
            label="用户密码"
            name="password"
            rules={[
              {
                required: true,
                message: '用户名称必填!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}

        <Form.Item label="账户类型" name="account_type">
          <Select>
            {platform_role === '3' ? (
              <Select.Option value="3">官方平台</Select.Option>
            ) : (
              <>
                <Select.Option value="1">商户</Select.Option>
                <Select.Option value="2">B级面料商</Select.Option>
              </>
            )}
          </Select>
        </Form.Item>
        {!isEdit ? (
          <Form.Item
            label="用户头像"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <div className={styles.avatarUpload}>
              <Upload onChange={getAvatar} />

              {imageUrl ? (
                <Image className={styles.avatar} width={100} src={imageUrl} />
              ) : null}
            </div>
          </Form.Item>
        ) : null}

        <Form.Item label="账户状态" name="status">
          <Select defaultValue="1">
            <Select.Option value="1">正常</Select.Option>
            <Select.Option value="2">禁用</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="用户角色" name="role">
          <Select>
            {job.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="绑定电脑" name="computer_tag">
          <div className={styles.computer_tag}>
            <Checkbox
              checked={computerTag !== 'open'}
              onChange={getCode}
              style={{ marginRight: '14px' }}
            ></Checkbox>
            <Input
              disabled
              value={computerTag === 'open' ? '不绑定' : computerTag}
              onChange={(e) => {
                setComputerTag(e.target.value);
              }}
            />
            {/* <Button type="primary" onClick={getCode}>获取本机唯一码</Button> */}
          </div>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Com;
