// 权限列表
export const powerList = [
  // {
  //   path: "/product",
  //   code: "/product",
  //   name: "产品管理",
  //   children: [
  //     {
  //       path: "/product/category",
  //       code: "/product/category",
  //       name: "产品分类",
  //       permission: [
  //         {
  //           code: "productCategoryIsShow",
  //           name: "是否显示",
  //         },
  //         {
  //           code: "productCategoryEdit",
  //           name: "编辑",
  //         },
  //         {
  //           code: "productCategoryDelete",
  //           name: "删除",
  //         },
  //         {
  //           code: "productCategoryFind",
  //           name: "查询",
  //         },
  //         {
  //           code: "productCategoryAdd",
  //           name: "新增分类",
  //         },
  //       ],
  //     },
  //     {
  //       path: "/product/list",
  //       code: "/product/list",
  //       name: "产品列表",
  //       permission: [
  //         {
  //           code: "productListImage",
  //           name: "封面图",
  //         },
  //         {
  //           code: "productListStatus",
  //           name: "上架状态",
  //         },
  //         {
  //           code: "productListModelConfig",
  //           name: "模型配置",
  //         },
  //         {
  //           code: "productListEdit",
  //           name: "编辑",
  //         },
  //         {
  //           code: "productListCode",
  //           name: "二维码",
  //         },
  //         {
  //           code: "productListDelete",
  //           name: "删除",
  //         },
  //         {
  //           code: "productListAdd",
  //           name: "新增产品",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: '/company',
    code: '/company',
    name: '商户管理',
    children: [
      {
        path: '/company/list',
        code: '/company/list',
        name: '商户列表',
      },
      {
        path: '/company/master_account',
        code: '/company/master_account',
        name: '商户主账号列表',
      },
    ],
  },
  {
    path: '/model',
    code: '/model',
    name: '模型管理',
    children: [
      {
        path: '/model/category',
        code: '/model/category',
        name: '模型分类',
        permission: [
          {
            code: 'modelCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'modelCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'modelCategoryEdit',
            name: '编辑',
          },
          {
            code: 'modelCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'modelCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'modelCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/model/list',
        code: '/model/list',
        name: '模型列表',
        permission: [
          {
            code: 'modelListFind',
            name: '查询',
          },
          {
            code: 'modelListEdit',
            name: '编辑',
          },
          {
            code: 'modelListView',
            name: '预览',
          },
          {
            code: 'modelListAdd',
            name: '新增',
          },
          {
            code: 'modelListPublish',
            name: '发布',
          },
        ],
      },
    ],
  },
  {
    path: '/vdesign',
    code: '/vdesign',
    name: '设计管理',
    children: [
      {
        path: '/vdesign/category',
        code: '/vdesign/category',
        name: '设计分类',
        permission: [
          {
            code: 'vdesignCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'vdesignCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'vdesignCategoryEdit',
            name: '编辑',
          },
          {
            code: 'vdesignCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'vdesignCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'vdesignCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/vdesign/list',
        code: '/vdesign/list',
        name: '设计列表',
        permission: [
          {
            code: 'vdesignListStatus',
            name: '上架状态',
          },
          {
            code: 'vdesignListLightType',
            name: '灯光类型',
          },
          {
            code: 'vdesignListChangeOpen',
            name: '公开/取消公开',
          },
          {
            code: 'vdesignListDesign',
            name: '设计',
          },
          {
            code: 'vdesignListPart',
            name: '部件组合',
          },
          // {
          //   code: "vdesignListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },

  {
    path: '/imgfabric',
    code: '/imgfabric',
    name: '成品面料',
    children: [
      {
        path: '/imgfabric/category',
        code: '/imgfabric/category',
        name: '面料分类',
        permission: [
          {
            code: 'imgfabricCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'imgfabricCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'imgfabricCategoryEdit',
            name: '编辑',
          },
          {
            code: 'imgfabricCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'imgfabricCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'imgfabricCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/imgfabric/list',
        code: '/imgfabric/list',
        name: '面料列表',
        permission: [
          {
            code: 'imgfabricListFind',
            name: '查询',
          },
          {
            code: 'imgfabricListAdd',
            name: '新增',
          },
          {
            code: 'imgfabricListView',
            name: '封面预览',
          },
          {
            code: 'imgfabricListIsShow',
            name: '是否显示',
          },
          {
            code: 'imgfabricListEdit',
            name: '编辑',
          },
          {
            code: 'imgfabricListOpen',
            name: '公开',
          },
          // {
          //   code: "imgfabricListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },
  {
    path: '/customimgfabric',
    code: '/customimgfabric',
    name: '自定义面料',
    children: [
      {
        path: '/customimgfabric/fabric',
        code: '/customimgfabric/fabric',
        name: '底胚管理',
        children: [
          {
            path: '/customimgfabric/fabric/category',
            code: '/customimgfabric/fabric/category',
            name: '底胚分类',
            permission: [
              {
                code: 'customimgfabricFabricCategoryAddOneLevelCategory',
                name: '新增一级分类',
              },
              {
                code: 'customimgfabricFabricCategoryStatus',
                name: '分类状态',
              },
              {
                code: 'customimgfabricFabricCategoryEdit',
                name: '编辑',
              },
              {
                code: 'customimgfabricFabricCategoryAddChildrenCategory',
                name: '新增子分类',
              },
              {
                code: 'customimgfabricFabricCategoryMoveUp',
                name: '上移',
              },
              {
                code: 'customimgfabricFabricCategoryDown',
                name: '下移',
              },
            ],
          },
          {
            path: '/customimgfabric/fabric/list',
            code: '/customimgfabric/fabric/list',
            name: '底胚列表',
            permission: [
              {
                code: 'customimgfabricFabricListFind',
                name: '查询',
              },
              {
                code: 'customimgfabricFabricListAdd',
                name: '新增',
              },
              {
                code: 'customimgfabricFabricListView',
                name: '封面预览',
              },
              {
                code: 'customimgfabricFabricListIsShow',
                name: '是否显示',
              },
              {
                code: 'customimgfabricFabricListEdit',
                name: '编辑',
              },
              {
                code: 'customimgfabricFabricListOpen',
                name: '公开',
              },
              {
                code: 'customimgfabricListDelete',
                name: '删除',
              },
            ],
          },
        ],
      },
      {
        path: '/customimgfabric/imgfabric',
        code: '/customimgfabric/imgfabric',
        name: '花型管理',
        children: [
          {
            path: '/customimgfabric/imgfabric/category',
            code: '/customimgfabric/imgfabric/category',
            name: '花型分类',
            permission: [
              {
                code: 'customImgFabricCategoryAddOneLevelCategory',
                name: '新增一级分类',
              },
              {
                code: 'customImgFabricCategoryStatus',
                name: '分类状态',
              },
              {
                code: 'customImgFabricCategoryEdit',
                name: '编辑',
              },
              {
                code: 'customImgFabricCategoryAddChildrenCategory',
                name: '新增子分类',
              },
              {
                code: 'customImgFabricCategoryMoveUp',
                name: '上移',
              },
              {
                code: 'customImgFabricCategoryDown',
                name: '下移',
              },
            ],
          },
          {
            path: '/customimgfabric/imgfabric/list',
            code: '/customimgfabric/imgfabric/list',
            name: '花型列表',
            permission: [
              {
                code: 'customImgFabricListFind',
                name: '查询',
              },
              {
                code: 'customImgFabricListAdd',
                name: '新增',
              },
              {
                code: 'customImgFabricListView',
                name: '封面预览',
              },
              {
                code: 'customImgFabricListIsShow',
                name: '是否显示',
              },
              {
                code: 'customImgFabricListEdit',
                name: '编辑',
              },
              {
                code: 'customImgFabricListOpen',
                name: '公开',
              },
              {
                code: 'customImgFabricListDelete',
                name: '删除',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/assistfabric',
    code: '/assistfabric',
    name: '辅料管理',
    children: [
      {
        path: '/assistfabric/category',
        code: '/assistfabric/category',
        name: '辅料分类',
        permission: [
          {
            code: 'assistfabricCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'assistfabricCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'assistfabricCategoryEdit',
            name: '编辑',
          },
          {
            code: 'assistfabricCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'assistfabricCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'assistfabricCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/assistfabric/list',
        code: '/assistfabric/list',
        name: '辅料列表',
        permission: [
          {
            code: 'assistfabricListFind',
            name: '查询',
          },
          {
            code: 'assistfabricListAdd',
            name: '新增',
          },
          {
            code: 'assistfabricListView',
            name: '封面预览',
          },
          {
            code: 'assistfabricListIsShow',
            name: '是否显示',
          },
          {
            code: 'assistfabricListEdit',
            name: '编辑',
          },
          {
            code: 'assistfabricListOpen',
            name: '公开',
          },
          // {
          //   code: "assistfabricListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },
  {
    path: '/pc/pattern',
    code: '/pc/pattern',
    name: '定位花管理',
    children: [
      {
        path: '/pc/pattern/category',
        code: '/pc/pattern/category',
        name: '定位花分类',
        permission: [
          {
            code: 'pcPatternCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'pcPatternCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'pcPatternCategoryEdit',
            name: '编辑',
          },
          {
            code: 'pcPatternCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'pcPatternCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'pcPatternCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/pc/pattern/list',
        code: '/pc/pattern/list',
        name: '定位花列表',
        permission: [
          {
            code: 'pcPatternListFind',
            name: '查询',
          },
          {
            code: 'pcPatternListAdd',
            name: '新增',
          },
          {
            code: 'pcPatternListView',
            name: '封面预览',
          },
          {
            code: 'pcPatternListIsShow',
            name: '是否显示',
          },
          {
            code: 'pcPatternListEdit',
            name: '编辑',
          },
          {
            code: 'pcPatternListOpen',
            name: '公开',
          },
          // {
          //   code: "pcPatternListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },
  {
    path: '/scene',
    code: '/scene',
    name: '背景管理',
    children: [
      {
        path: '/scene/category',
        code: '/scene/category',
        name: '背景分类',
        permission: [
          {
            code: 'sceneCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'sceneCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'sceneCategoryEdit',
            name: '编辑',
          },
          {
            code: 'sceneCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'sceneCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'sceneCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/scene/list',
        code: '/scene/list',
        name: '背景列表',
        permission: [
          {
            code: 'sceneListFind',
            name: '查询',
          },
          {
            code: 'sceneListAdd',
            name: '新增',
          },
          {
            code: 'sceneListView',
            name: '封面预览',
          },
          {
            code: 'sceneListIsShow',
            name: '是否显示',
          },
          {
            code: 'sceneListEdit',
            name: '编辑',
          },
          {
            code: 'sceneListOpen',
            name: '公开',
          },
          // {
          //   code: "sceneListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },
  {
    path: '/pc/chartlet',
    code: '/pc/chartlet',
    name: '贴图管理',
    children: [
      {
        path: '/pc/chartlet/category',
        code: '/pc/chartlet/category',
        name: '贴图分类',
        permission: [
          {
            code: 'pcChartletCategoryAddOneLevelCategory',
            name: '新增一级分类',
          },
          {
            code: 'pcChartletCategoryStatus',
            name: '分类状态',
          },
          {
            code: 'pcChartletCategoryEdit',
            name: '编辑',
          },
          {
            code: 'pcChartletCategoryAddChildrenCategory',
            name: '新增子分类',
          },
          {
            code: 'pcChartletCategoryMoveUp',
            name: '上移',
          },
          {
            code: 'pcChartletCategoryDown',
            name: '下移',
          },
        ],
      },
      {
        path: '/pc/chartlet/list',
        code: '/pc/chartlet/list',
        name: '贴图列表',
        permission: [
          {
            code: 'pcChartletListFind',
            name: '查询',
          },
          {
            code: 'pcChartletListAdd',
            name: '新增',
          },
          {
            code: 'pcChartletListView',
            name: '封面预览',
          },
          {
            code: 'pcChartletListIsShow',
            name: '是否显示',
          },
          {
            code: 'pcChartletListEdit',
            name: '编辑',
          },
          {
            code: 'pcChartletListOpen',
            name: '公开',
          },
          // {
          //   code: "pcPatternListDelete",
          //   name: "删除",
          // },
        ],
      },
    ],
  },
];

export const accountMenu = {
  path: '/account',
  code: '/account',
  name: '账号管理',
  children: [
    {
      path: '/account/job',
      code: '/account/job',
      name: '职位列表',
    },
    // {
    //   path: "/account/company",
    //   code: "/account/company",
    //   name: "商户列表",
    // },
  ],
};
export const playMenu=[
  {
    path: '/product',
    code: '/product',
    name: '产品管理',
    children: [
      {
        path: '/product/category',
        code: '/product/category',
        name: '产品分类',
      },
      {
        path: "/product/list",
        code: "/product/list",
        name: "产品列表",
      }
      // , {
      //   path: "/product/mconfig",
      //   code: "/product/mconfig",
      //   name: "模型配置",
      // },
    ],
  },
  {
    path: '/pattern',
    code: '/pattern',
    name: '定位花管理',
    children: [
      {
        path: '/pattern/category',
        code: '/pattern/category',
        name: '定位花分类',
      },
      {
        path: "/pattern/list",
        code: "/pattern/list",
        name: "定位花列表",
      },
    ],
  },
  {
    path: '/chartlet',
    code: '/chartlet',
    name: '贴图管理',
    children: [
      {
        path: '/chartlet/category',
        code: '/chartlet/category',
        name: '贴图分类',
      },
      {
        path: "/chartlet/list",
        code: "/chartlet/list",
        name: "贴图列表",
      },
    ],
  },
  {
    path: '/font',
    code: '/font',
    name: '字体管理',
    children: [
      {
        path: "/font/list",
        code: "/font/list",
        name: "字体列表",
      },
    ],
  },
  {
    path: '/order',
    code: '/order',
    name: '订单管理',
    children: [
      {
        path: '/order/list',
        code: '/order/list',
        name: '订单列表',
      },
      {
        path: "/export/list",
        code: "/export/list",
        name: "导出列表",
      },
    ],
  }, {
    path: '/config',
    code: '/config',
    name: '配置管理',
    children: [
      {
        path: "/config/app",
        code: "/config/app",
        name: "应用配置",
      },
    ],
  }
]

export const fashionShowAll_menu = [
  {
    path: '/fashionShow',
    code: '/fashionShow',
    name: '走秀动画管理',
    children: [
      {
        path: '/fashionShow/approve',
        code: '/fashionShow/approve',
        name: '走秀动画审批',
        permission: [
          {
            code: 'fashionShowApproveQuery',
            name: '查询',
          },
          {
            code: 'fashionShowApproveBtn',
            name: '审批',
          },
          {
            code: 'fashionShowCancelBtn',
            name: '撤销',
          }
        ],
      },
      {
        path: '/fashionShow/upframe',
        code: '/fashionShow/upframe',
        name: '走秀动画上架',
        permission: [
          {
            code: 'fashionShowUpframeQuery',
            name: '查询',
          },
          {
            code: 'fashionShowUpframeUpload',
            name: '动画上传',
          },
          {
            code: 'fashionShowUpframeBtn',
            name: '上架',
          },
          {
            code: 'fashionShowDownframe',
            name: '下架',
          },
        ],
      },
    ],
  },
]

export const fashionShow_approve_menu = [
  {
    path: '/fashionShow',
    code: '/fashionShow',
    name: '走秀动画管理',
    children: [
      {
        path: '/fashionShow/approve',
        code: '/fashionShow/approve',
        name: '走秀动画审批',
        permission: [
          {
            code: 'fashionShowApproveQuery',
            name: '查询',
          },
          {
            code: 'fashionShowApproveBtn',
            name: '审批',
          },
          {
            code: 'fashionShowCancelBtn',
            name: '撤销',
          }
        ],
      },
    ],
  },
]
