import { Tree, Button, message } from 'antd';
import React, { useState, useEffect, useMemo } from 'react';

import { powerList } from '../../../utils/powerList';

import http from '../../../lib/http';
import * as apis from '../../../lib/account';

import styles from './index.module.css';

const findMenu = (power, path) => {
  for (let i = 0, len = power.length; i < len; i++) {
    const item = power[i];
    if (item.path === path) {
      return item;
    } else {
      if (item.children && item.children.length > 0) {
        let res = findMenu(item.children, path);
        if (res) return res;
      }
    }
  }
};

function Com(props) {
  const { selectJob, currentRoleId, onSave, platform_role } = props;
  const [menu, setMenu] = useState([]);
  const [job, setJob] = useState({});
  const [selectedPermission, setSelectedPermission] = useState([]);
  const powerDate = useMemo(() => {
    const dataList = JSON.parse(JSON.stringify(powerList));
    if (String(platform_role) !== '3') {
      const index = dataList.findIndex((e) => e.name == '商户管理');
      if (index !== -1) {
        dataList.splice(index, 1);
      }
    }
    return dataList;
  }, []);

  const onCheck = (selectedKeysValue) => {
    setSelectedPermission(selectedKeysValue);
  };

  const updateMenu = (page) => {
    page.key = page.key || `${page.path}`;
    page.title = page.name;

    if (page.permission) {
      const permissions = page.permission.map((item) => {
        item.key = `${item.code}-permission`;
        return item;
      });

      permissions.unshift({
        path: `${page.path}-pageView`,
        key: `${page.path}-pageView`,
        name: '页面查看',
      });

      if (page.children?.length) {
        page.children = [
          {
            path: `${page.path}-all`,
            name: '页面全部权限',
            children: permissions,
          },
          ...page.children,
        ];
      } else {
        page.children = [
          {
            path: `${page.path}-all`,
            name: '页面全部权限',
            children: permissions,
          },
        ];
      }
    }
    if (page.children?.length) {
      page.children.forEach((item) => {
        updateMenu(item);
      });
    }
  };

  // 保存选择的权限
  const savePermissions = async () => {
    const pageSet = new Set([]);
    const btnSet = new Set([]);
    selectedPermission?.forEach?.((item) => {
      if (item.indexOf('-pageView') !== -1) {
        btnSet.add(item);
        let menus = item.replace('-pageView', '').split('/');
        menus.shift();
        let page = '';
        menus.forEach((item) => {
          page += `/${item}`;
          pageSet.add(page);
        });
      } else if (item.indexOf('permission') === -1) {
        if (item.indexOf('all-page') === -1) {
          // pageSet.add(item.replace('-page', ''))
          // 菜单权限
          let menus = item.replace('-page', '').split('/');
          menus.shift();
          let page = '';
          menus.forEach((item) => {
            page += `/${item}`;
            pageSet.add(page);
          });
        }
      } else {
        // 页面权限
        let btn = item.replace('-permission', '');
        btnSet.add(btn);
      }
    });

    const menuPermission = [];
    [...pageSet].forEach((item) => {
      const findItem = findMenu(powerList, item);
      if (findItem) {
        menuPermission.push({
          path: findItem.path,
          name: findItem.name,
        });
      }
    });

    const selectPermission = {
      menu: menuPermission,
      permission: [...btnSet],
      selectedPermission,
    };

    // 保存权限
    await http.post(apis.rolePermissionUpdate, {
      id: job.id,
      role_id: selectJob.id,
      content: JSON.stringify(selectPermission),
    });

    message.success('权限保存成功');
    onSave();
  };

  const handleDataEcho = async () => {
    // 获取角色信息
    const data = await http.post(apis.rolePermission, {
      role_id: selectJob.id,
    });

    // console.log(1, data);
    if (data.content) {
      const { menu, permission, selectedPermission } = JSON.parse(data.content);

      const selectedKey = selectedPermission;

      // permission.forEach(item => {
      //     if (item.indexOf('-pageView') !== -1) {
      //         selectedKey.push(item)
      //     } else {

      //         selectedKey.push(`${item}-permission`)
      //     }
      // })
      // menu?.forEach(item => {
      //     if(){

      //         selectedKey.push(`${item.path}`)
      //     }
      //     // selectedKey.push(`${item.path}-pageView`)
      // })

      setSelectedPermission(selectedKey);
    }
    setJob(data);
  };

  useEffect(() => {
    handleDataEcho();
    powerDate.forEach((item) => {
      updateMenu(item, item.id);
    });

    setMenu(powerDate);
  }, []);

  // console.log(menu);

  return (
    <div className="power-content">
      <div className={styles.powerSelect}>
        <div className={styles.powerItem}>
          {/* 页面权限 */}
          {/* <Space>页面权限</Space> */}
          <Tree
            onCheck={onCheck}
            checkable
            checkedKeys={selectedPermission}
            defaultExpandParent
            treeData={menu}
            blockNode
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button onClick={savePermissions} type="primary">
          保存
        </Button>
      </div>
    </div>
  );
}

export default Com;
