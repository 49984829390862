

export const handleLevelData = (data) => {
    const one = []
    for(let i=0;i<data.length;i++){
        let item = data[i]
        item.label = item.name
        item.value = item.id
        item.key = item.id.toString()
        if(item.super_id === 0){
            one.push(item)
        }
    }
    return a(one,data)
}

const finds = (data,id) => {
    let con = []
    for(let i=0;i<data.length;i++){
        if(data[i].super_id === id){
            con.push(data[i])
        }
    }
    return con
}

const a = (one,data) => {
    for(let i=0;i<one.length;i++){
        let item = one[i]
        let fs = finds(data,item.id)
        if(fs.length !== 0){
            item.children = fs
            a(item.children, data)
        }
    }
    return one
}

export const isVip = (c) => {
    return c?.roleData?.role === 4
}

export const isThreeSdk = (c) => {
    return c?.roleData?.role === 5
}

export const sleep = async (s) => {
    return new Promise((resolve, reject) => {
        setTimeout(()=>{resolve()},s)
    })
}