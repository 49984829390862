import { Button, Input, Modal, message } from "antd"
import { useImperativeHandle, useState } from 'react';
import loadFont from '../../lib/font'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fontFamily, setFontFamily] = useState(null)
    const [txt, setTxt] = useState("")


    const setShow = async (isShow,v) => {
        setIsModalOpen(isShow)
        if(isShow){
            setTxt("")
            setLoading(true)
            try {
                await loadFont(`font${v.id}`, process.env.REACT_APP_OSS_URL+v.path)
                setFontFamily(`font${v.id}`)
            } catch (error) {
                message.error("字体加载失败")
            }
            setLoading(false)
        }
    }

    useImperativeHandle(props.funcs, () => ({
        setShow,
    }));



    return (
        <>
            <Modal 
                forceRender
                open={isModalOpen} 
                onCancel={() => {
                    if(loading) return 
                    setIsModalOpen(false)
                }}
                title="预览字体"
                footer={
                    <>
                        <Button loading={loading} type="primary" onClick={() => setIsModalOpen(false)}>确定</Button>
                    </>
                }
            >
                <Input.TextArea onChange={e => setTxt(e.target.value)} value={txt} style={fontFamily ? {fontFamily}:{}} placeholder="请输入文字或字母预览效果" />
            </Modal>
        </>
    )
}

export default Index