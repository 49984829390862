import React, { useState, useEffect, useMemo } from "react";
import { Button, Form, Input, Select, Image } from "antd";

import Upload from "../../../components/upload";
import http from "../../../lib/http";
import * as apis from "../../../lib/account";

import styles from "./index.module.css";

function Com(props) {
    const { onSave, selectCompany } = props;

    const [form] = Form.useForm();
    const [companyList, setCompanyList] = useState([]);
    const [imageUrl, setImageUrl] = useState();

    const onFinish = (values) => {
        const formValue = {
            ...values,
            avatar: imageUrl,
        };

        onSave(formValue);
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const getAvatar = (data) => {
        if (data[0]?.status === "done") {
            const url = data[0].thumbUrl;
            setImageUrl(url);
        }
    };

    // 获取商户
    const getCompany = async () => {
        const data = await http.post(apis.companyList, { page: 1, page_size: 10000 });
        if (data?.list) {
            setCompanyList(data.list);
        }
    }

    useEffect(() => {
        getCompany();
        // if (selectCompany) {
        //     form.setFieldsValue({
        //         ...selectCompany
        //     });
        // } else {
        //     form.resetFields();
        // }
    }, [selectCompany]);

    return (
        <div className="createUser-content">
            <Form
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                onFinish={onFinish}
            >

                <Form.Item
                    label="帐号"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: "商户帐号必填!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="密码"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "商户密码必填!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="姓名"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "商户姓名必填!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="所属商户"
                    name="company_id"
                    rules={[
                        {
                            required: true,
                            message: "所属商户必填!",
                        },
                    ]}
                >
                    <Select>
                        {companyList.map((item) => {
                            return (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="用户头像"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <div className={styles.avatarUpload}>
                        <Upload onChange={getAvatar} />

                        {imageUrl ? (
                            <Image className={styles.avatar} width={100} src={imageUrl} />
                        ) : null}
                    </div>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Com;
