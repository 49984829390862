export const LOGIN = `/merchant/client/open/login`
export const PC_TOKEN = `/merchant/client/api/pc/token`
export const USER_INFO = `/merchant/client/api/user/info`
export const ALI_STS = `/merchant/client/api/sts/token`
export const UUID = `/uuid`
export const PRODUCT_CATEGORY_LIST = `/merchant/client/api/product/category/list`
export const PRODUCT_CATEGORY_SHOW_UPDATE = `/merchant/client/api/product/category/show/update`
export const PRODUCT_CATEGORY_CREATE = `/merchant/client/api/product/category/create`
export const PRODUCT_CATEGORY_UPDATE = `/merchant/client/api/product/category/update`
export const PRODUCT_CATEGORY_DELETE = `/merchant/client/api/product/category/delete`

export const USER_LIST = `/merchant/client/api/user/list`

export const USER_FORBIDDEN = `/merchant/client/api/user/forbidden`


export const PATTERN_CATEGORY_LIST = `/merchant/client/api/pattern/category/list`
export const PATTERN_CATEGORY_SHOW_UPDATE = `/merchant/client/api/pattern/category/status/update`
export const PATTERN_CATEGORY_CREATE = `/merchant/client/api/pattern/category/create`
export const PATTERN_CATEGORY_UPDATE = `/merchant/client/api/pattern/category/update`
export const PATTERN_CATEGORY_DELETE = `/merchant/client/api/pattern/category/delete`
export const PATTERN_LIST = `/merchant/client/api/pattern/list`
export const PATTERN_SHOW_UPDATE = `/merchant/client/api/pattern/status/update`
export const PATTERN_CREATE = `/merchant/client/api/pattern/create`
export const PATTERN_UPDATE = `/merchant/client/api/pattern/update`
export const PATTERN_DELETE = `/merchant/client/api/pattern/delete`

export const FABRIC_CATEGORY_LIST = `/merchant/client/api/fabric/category/list`
export const FABRIC_CATEGORY_SORT_UPDATE = `/merchant/client/api/fabric/category/sort/update`
export const FABRIC_PUB_CATEGORY_LIST = `/merchant/client/api/fabric/pub/category/list`
export const FABRIC_CATEGORY_SHOW_UPDATE = `/merchant/client/api/fabric/category/status/update`
export const FABRIC_CATEGORY_CREATE = `/merchant/client/api/fabric/category/create`
export const FABRIC_CATEGORY_UPDATE = `/merchant/client/api/fabric/category/update`
export const FABRIC_CATEGORY_DELETE = `/merchant/client/api/fabric/category/delete`
export const FABRIC_ADMIN_LIST = `/merchant/client/api/fabric/admin/list`
export const FABRIC_SHOW_UPDATE = `/merchant/client/api/fabric/status/update`
export const FABRIC_PUB_UPDATE = `/merchant/client/api/fabric/pub/update`
export const FABRIC_CREATE = `/merchant/client/api/fabric/create`
export const FABRIC_UPDATE = `/merchant/client/api/fabric/update`
export const FABRIC_DELETE = `/merchant/client/api/fabric/delete`


export const PC_PATTERN_CATEGORY_LIST = `/merchant/client/api/pc/pattern/category/list`
export const PC_PATTERN_CATEGORY_SORT_UPDATE = `/merchant/client/api/pc/pattern/category/sort/update`
export const PC_PATTERN_PUB_CATEGORY_LIST = `/merchant/client/api/pc/pattern/pub/category/list`
export const PC_PATTERN_CATEGORY_SHOW_UPDATE = `/merchant/client/api/pc/pattern/category/status/update`
export const PC_PATTERN_CATEGORY_CREATE = `/merchant/client/api/pc/pattern/category/create`
export const PC_PATTERN_CATEGORY_UPDATE = `/merchant/client/api/pc/pattern/category/update`
export const PC_PATTERN_CATEGORY_DELETE = `/merchant/client/api/pc/pattern/category/delete`
export const PC_PATTERN_ADMIN_LIST = `/merchant/client/api/pc/pattern/admin/list`
export const PC_PATTERN_SHOW_UPDATE = `/merchant/client/api/pc/pattern/status/update`
export const PC_PATTERN_PUB_UPDATE = `/merchant/client/api/pc/pattern/pub/update`
export const PC_PATTERN_CREATE = `/merchant/client/api/pc/pattern/create`
export const PC_PATTERN_UPDATE = `/merchant/client/api/pc/pattern/update`
export const PC_PATTERN_DELETE = `/merchant/client/api/pc/pattern/delete`

/** 贴图管理 */
export const PC_CHARTLET_CATEGORY_LIST = `/merchant/client/api/pc/chartlet/category/list`
export const PC_CHARTLET_CATEGORY_SORT_UPDATE = `/merchant/client/api/pc/chartlet/category/sort/update`
// export const PC_PATTERN_PUB_CATEGORY_LIST = `/merchant/client/api/pc/pattern/pub/category/list`
export const PC_CHARTLET_CATEGORY_SHOW_UPDATE = `/merchant/client/api/pc/chartlet/category/status/update`
export const PC_CHARTLET_CATEGORY_CREATE = `/merchant/client/api/pc/chartlet/category/create`
export const PC_CHARTLET_CATEGORY_UPDATE = `/merchant/client/api/pc/chartlet/category/update`
// export const PC_PATTERN_CATEGORY_DELETE = `/merchant/client/api/pc/pattern/category/delete`
export const PC_CHARTLET_ADMIN_LIST = `/merchant/client/api/pc/chartlet/admin/list`
export const PC_CHARTLET_SHOW_UPDATE = `/merchant/client/api/pc/chartlet/status/update`
export const PC_CHARTLET_PUB_UPDATE = `/merchant/client/api/pc/chartlet/pub/update`
export const PC_CHARTLET_CREATE = `/merchant/client/api/pc/chartlet/create`
export const PC_CHARTLET_UPDATE = `/merchant/client/api/pc/chartlet/update`
export const PC_CHARTLET_DELETE = `/merchant/client/api/pc/chartlet/delete`

export const DESIGN_ADMIN_CATEGORY_LIST = `/merchant/client/api/design/category/admin/list`
export const DESIGN_CATEGORY_LIST = `/merchant/client/api/design/category/list`
export const DESIGN_CATEGORY_SORT_UPDATE = `/merchant/client/api/design/category/sort/update`
export const DESIGN_PUB_CATEGORY_LIST = `/merchant/client/api/design/pub/category/list`
export const DESIGN_CATEGORY_SHOW_UPDATE = `/merchant/client/api/design/category/status/update`
export const DESIGN_CATEGORY_CREATE = `/merchant/client/api/design/category/create`
export const DESIGN_ADMIN_CATEGORY_CREATE = `/merchant/client/api/design/category/admin/create`
export const DESIGN_CATEGORY_UPDATE = `/merchant/client/api/design/category/update`
export const DESIGN_CATEGORY_DELETE = `/merchant/client/api/design/category/delete`
export const DESIGN_ADMIN_LIST = `/merchant/client/api/design/admin/list`
export const DESIGN_ADMIN_SHOW_UPDATE = `/merchant/client/api/design/status/admin/update`
export const DESIGN_ADMIN_LIGHT_TYPE_UPDATE = `/merchant/client/api/design/light_type/admin/update`
export const DESIGN_PUB_UPDATE = `/merchant/client/api/design/pub/update`
export const DESIGN_CREATE = `/merchant/client/api/admin/design/create`
export const DESIGN_COPY = `/merchant/client/api/admin/design/copy`
export const DESIGN_UPDATE = `/merchant/client/api/design/update`
export const DESIGN_ADMIN_DELETE = `/merchant/client/api/design/admin/delete`
export const DESIGN_DELETE = `/merchant/client/api/design/delete`
export const DESIGN_COMBINATION = `/merchant/client/api/design/part/combination`



export const DESIGN_VIP_LIST = `/merchant/client/api/design/vip/list`


export const SCENE_CATEGORY_LIST = `/merchant/client/api/scene/category/list`
export const SCENE_CATEGORY_SORT_UPDATE = `/merchant/client/api/scene/category/sort/update`
export const SCENE_PUB_CATEGORY_LIST = `/merchant/client/api/scene/pub/category/list`
export const SCENE_CATEGORY_SHOW_UPDATE = `/merchant/client/api/scene/category/status/update`
export const SCENE_CATEGORY_CREATE = `/merchant/client/api/scene/category/create`
export const SCENE_CATEGORY_UPDATE = `/merchant/client/api/scene/category/update`
export const SCENE_CATEGORY_DELETE = `/merchant/client/api/scene/category/delete`
export const SCENE_ADMIN_LIST = `/merchant/client/api/scene/admin/list`
export const SCENE_SHOW_UPDATE = `/merchant/client/api/scene/status/update`
export const SCENE_PUB_UPDATE = `/merchant/client/api/scene/pub/update`
export const SCENE_CREATE = `/merchant/client/api/scene/create`
export const SCENE_UPDATE = `/merchant/client/api/scene/update`
export const SCENE_DELETE = `/merchant/client/api/scene/delete`

export const MODEL_CATEGORY_LIST = `/merchant/client/api/model/category/list`
export const MODEL_CATEGORY_SORT_UPDATE = `/merchant/client/api/model/category/sort/update`
export const MODEL_PUB_CATEGORY_LIST = `/merchant/client/api/model/pub/category/list`
export const MODEL_CATEGORY_SHOW_UPDATE = `/merchant/client/api/model/category/status/update`
export const MODEL_CATEGORY_CREATE = `/merchant/client/api/model/category/create`
export const MODEL_CATEGORY_UPDATE = `/merchant/client/api/model/category/update`
export const MODEL_CATEGORY_DELETE = `/merchant/client/api/model/category/delete`
export const MODEL_LIST = `/merchant/client/api/model/list`
export const MODEL_CREATE = `/merchant/client/api/model/create`
export const MODEL_UPDATE = `/merchant/client/api/model/update`
export const MODEL_ITEMS_UPDATE = `/merchant/client/api/model/items/update`
export const MODEL_DELETE = `/merchant/client/api/model/delete`
export const MODEL_DETAIL = `/merchant/client/api/model/detail`

export const FONT_LIST = `/merchant/client/api/font/list`
export const FONT_SHOW_UPDATE = `/merchant/client/api/font/status/update`
export const FONT_CREATE = `/merchant/client/api/font/create`
export const FONT_UPDATE = `/merchant/client/api/font/update`
export const FONT_DELETE = `/merchant/client/api/font/delete`

export const PRODUCT_LIST = `/merchant/client/api/product/list`
export const PRODUCT_SHOW_UPDATE = `/merchant/client/api/product/status/update`
export const PRODUCT_CREATE = `/merchant/client/api/product/create`
export const PRODUCT_UPDATE = `/merchant/client/api/product/update`
export const PRODUCT_DELETE = `/merchant/client/api/product/delete`
export const PRODUCT_MATERIALS_UPDATE = `/merchant/client/api/product/materials/update`
export const PRODUCT_DETAIL = `/merchant/client/api/product/detail`

export const INDEX_DATA = `/merchant/client/api/index/data`
export const INDEX_DATA_UPDATE = `/merchant/client/api/index/data/update`

export const ORDER_LIST = `/merchant/client/api/order/list`



export const ORDER_DOWNLOAD_TASK_CREATE = `/merchant/client/api/order/materials/download/task/create`

export const ORDER_DOWNLOAD_TASK_LIST = `/merchant/client/api/order/materials/download/task/list`

/** 查询时装秀列表 */
export const fashionShowPageApi = `/merchant/client/api/fashionShow/list`
/** 申请时装秀 */
export const fashionShowApplyApi = `/merchant/client/api/fashionShow/apply`
/** 取消时装秀 */
export const fashionShowCancelApi = `/merchant/client/api/fashionShow/cancel`
/** 拒绝时间秀申请 */
export const fashionShowRefuseApi = `/merchant/client/api/fashionShow/refuse`
/** 通过时装秀申请 */
export const fashionShowPassApi = `/merchant/client/api/fashionShow/pass`
/** 上传时装秀资源 */
export const fashionShowUploadApi = `/merchant/client/api/fashionShow/upload`
/** 上架时装秀申请 */
export const fashionShowUpframeApi = `/merchant/client/api/fashionShow/upframe`
/** 下架时装秀 */
export const fashionShowDownFrameApi = `/merchant/client/api/fashionShow/downframe`










