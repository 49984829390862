import { Slider, Switch, Form } from 'antd';
import { useState } from 'react'
import { cloneDeep } from 'lodash'

const offsetNum = 26

const ms = {}
ms[0] = '0'

const Index = (props) => {
    const {value = {}} = props
    return (
        <>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingBottom: 5}}>平铺模式：</div>
                <div>
                    <Switch checked={value.repeat} onChange={e => {
                        let cpValue = cloneDeep(value)
                        cpValue.repeat = e
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingBottom: 5}}>透明模式：</div>
                <div>
                    <Switch checked={value.transparent} onChange={e => {
                        let cpValue = cloneDeep(value)
                        cpValue.transparent = e
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
            <div style={{display: value.repeat ? "block":"none"}}>
                <div>缩放：</div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div>
                        <Slider marks={ms} style={{width: 300}} value={value.repeatNum-offsetNum} min={-30} max={30} onChange={(v) => {
                            let cpValue = cloneDeep(value)
                            cpValue.repeatNum = v+offsetNum
                            cpValue.repeatNumY = v+offsetNum
                            props.onChange(cpValue)
                        }} />
                    </div>
                </div>
            </div>
            <div style={{display: "none", alignItems: "center"}}>
                <div>Y轴缩放：</div>
                <div>
                    <Slider style={{width: 300}} value={value.repeatNumY} min={-30} max={30} onChange={(v) => {
                        let cpValue = cloneDeep(value)
                        cpValue.repeatNumY = v
                        props.onChange(cpValue)
                    }} />
                </div>
            </div>
        </>
    )
}

export default Index