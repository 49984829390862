import React, { useEffect, useState } from "react";
import { Table, Button, Space, message, Spin } from 'antd'
import { ReloadOutlined } from '@ant-design/icons';
import * as apis from '../../../lib/api'
import http from '../../../lib/http'
import _ from 'lodash'
import { useNavigate } from "react-router-dom";


const Index = () => {
    const [search, setSearch] = useState({page: 1,page_size: 20})
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const n = useNavigate()


    const loadData = async (s) => {
        try {
            const data = await http.post(apis.ORDER_DOWNLOAD_TASK_LIST,s)
            for(let i=0;i<data?.list?.length;i++){
                let item = data.list[i]
                try {
                    item.search_param = JSON.parse(item.search_param)
                } catch (error) {
                    
                }
            }
            setData(data)
        } catch (error) {
            
        }
    }

    const reload = () => {
        loadData(search)
    }

    const pageSearch = async (page,page_size) => {
        let cpSearch = {...search}
        cpSearch.page_size = page_size
        cpSearch.page = page
        setLoading(true)
        await loadData(cpSearch)
        setLoading(false)
        setSearch(cpSearch)
    }

    useEffect(()=>{
        setLoading(true)
        loadData(search).then(r => {
            setLoading(false)
        })
        // eslint-disable-next-line
    },[])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: '搜索条件',
            dataIndex: 'search_param',
            key: 'search_param',
            render: (v) => (
                <div>
                    <div>
                        <span>订单号：</span>
                        <span>{v?.order_nos?.join()}</span>
                    </div>
                    <div>
                        <span>名称：</span>
                        <span>{v?.name}</span>
                    </div>
                    <div>
                        <span>开始时间：</span>
                        <span>{v?.start_time}</span>
                    </div>
                    <div>
                        <span>结束时间：</span>
                        <span>{v?.end_time}</span>
                    </div>
                </div>
            )
        },
        {
            title: '导出数量',
            dataIndex: 'num',
            key: 'num'
        },
        {
            title: '失败数量',
            dataIndex: 'fail_num',
            key: 'fail_num'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (v) => {
                if(v == 1){
                    return <Space>
                        <span style={{color: "blue"}}>导出中</span><span><Button onClick={reload} icon={<ReloadOutlined />}></Button></span>
                    </Space>
                }
                if(v == 2){
                    return <span style={{color: "green"}}>导出完成</span>
                }
                if(v == 1){
                    return <span style={{color: "red"}}>导出失败</span>
                }
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: '操作',
            dataIndex: 'zip_path',
            key: 'zip_path',
            render: (v,r) => (
                <Space>
                    <Button disabled={r.status != 2} type="link" style={{padding: 0}} onClick={()=>{
                        window.location.href = process.env.REACT_APP_OSS_URL+v
                    }}>下载压缩包</Button>
                </Space>
            )
        }
    ]

    return (
        <div>
            <Table 
                rowKey={'id'}
                dataSource={data?.list || []} 
                loading={loading} 
                columns={columns}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: pageSearch,
                    total: data?.total,
                    current: data?.page,
                    pageSize: data?.page_size
                }}
                scroll={{
                    y: 'calc(100vh - 355px)'
                }}
            />
        </div>
    )
}

export default Index