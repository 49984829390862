import { Button, Form, Input, message, Modal } from "antd"
import { useImperativeHandle, useState } from 'react';
import * as apis from '../../../lib/api'
import http from '../../../lib/http'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fieldsValue, asetFieldsValue] = useState({})
    

    useImperativeHandle(props.funcs, () => ({
        setIsModalOpen,
        resetFields: form.resetFields,
        setFieldsValue: (vals) => {
            asetFieldsValue(vals)
            form.setFieldsValue(vals)
        }
    }));

    const submit = async () => {
        try {
            const data = await form.validateFields()
            if(!fieldsValue.id){
                setLoading(true)
                try {
                    await http.post(apis.PATTERN_CATEGORY_CREATE,{name: data.name})
                    setIsModalOpen(false)
                    message.success("新建成功")
                    props.onRefresh && props.onRefresh(1)
                } catch (error) {
                    
                }
                setLoading(false)
            }else{
                setLoading(true)
                try {
                    await http.post(apis.PATTERN_CATEGORY_UPDATE,{id: fieldsValue.id,name: data.name})
                    setIsModalOpen(false)
                    message.success("更新成功")
                    props.onRefresh && props.onRefresh()
                } catch (error) {
                    
                }
                setLoading(false)
            }
        } catch (error) {
            
        }
    }


    return (
        <>
            <Modal 
                forceRender
                open={isModalOpen} 
                onCancel={() => {
                    if(loading) return 
                    setIsModalOpen(false)
                }}
                title={!fieldsValue.id ? '新建分类':'更新分类'}
                footer={
                    <>
                        <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
                        <Button loading={loading} type="primary" onClick={submit}>确定</Button>
                    </>
                }
            >
                <Form form={form} autoComplete="off" labelAlign="right" labelCol={{span: 4}} style={{marginLeft: -10}}>
                    <Form.Item name="name" label="分类名称" rules={[{required: true, message: "请填写"}]}>
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Index