import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table, Modal, message } from 'antd';
import React, { useEffect, useRef, useState, useContext } from 'react';

import SelectPower from './selectPower';

import { globalContext } from '../../../components/context';

import http from '../../../lib/http';
import * as apis from '../../../lib/account';

import styles from './index.module.css';
import { jobContext } from '../../../components/context/job';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} className={styles.tr} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const [editing, setEditing] = useState(false);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (record.editable !== false) {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      const newData = {
        ...record,
        ...values,
      };

      await http.post(apis.roleUpdate, newData);
      message.success('编辑成功');
      handleSave(newData);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title}必须填写`,
          },
        ]}
      >
        <Input ref={inputRef} onBlur={save} className={styles.tdInput} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}{' '}
        {record.editable !== false && (
          <EditOutlined
            onClick={toggleEdit}
            style={{ color: '#168cff', cursor: 'pointer' }}
          />
        )}
      </div>
    );
  }
  return (
    <td {...restProps} className={styles.td}>
      {childNode}
    </td>
  );
};

const App = () => {
  const c = useContext(globalContext);
  const j = useContext(jobContext);
  const [loading, setLoading] = useState(false);
  const [selectJob, setSelectJob] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [search, setSearch] = useState({ page: 1, page_size: 10, total: 0 });
  const [platform_role, setPlatform_role] = useState(0);
  const getCompanyInfo = async () => {
    const companyInfo = await http.post(apis.companyInfo);
    setPlatform_role(companyInfo.platform_role);
  };
  const handleDelete = async (key) => {
    await http.post(
      apis.roleDelete,
      dataSource.find((item) => item.key === key)
    );
    loadData();
  };

  const selectJobSetPower = (job) => {
    setSelectJob(job);
    setIsVisible(true);
  };

  const defaultColumns = [
    {
      title: '职位',
      dataIndex: 'name',
      editable: true,
      width: '300px',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 && record.editable !== false ? (
          <div className={styles.operaButs}>
            <Button
              type="link"
              block
              className={styles.operaBut}
              onClick={() => {
                selectJobSetPower(record);
              }}
            >
              设置权限
            </Button>

            <Popconfirm
              title="确定删除这个职位吗?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type="link" block className={styles.operaBut}>
                删除
              </Button>
            </Popconfirm>
          </div>
        ) : null,
    },
  ];

  // 添加职位
  const handleAdd = async () => {
    const data = await http.post(apis.roleCreate, { name: '新职位1' });
    if (data) {
      message.success('新增成功');

      loadData();
      // setDataSource([...dataSource, { ...data, key: data.id }]);
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    loadData();
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // 设置权限
  const setJobPower = (power) => {
    console.log(power);
  };

  // 表格页数改变的时候
  const pageSearch = async (page, page_size) => {
    setSearch({ page: page, page_size: page_size, total: search.total });
  };

  const loadData = async () => {
    setLoading(true);
    const data = await http.post(apis.roleList, search);
    if (data?.list) {
      j.setJobChange(Date.now());
      setSearch({
        page: data.page,
        page_size: data.page_size,
        total: data.total,
      });
      setDataSource(
        data.list.map((item) => {
          item.key = item.id;
          return item;
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    getCompanyInfo();
  }, [search.page, search.total, search.page_size]);

  return (
    <div className={styles.job}>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        新增职位
      </Button>
      <Table
        loading={loading}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: pageSearch,
          total: search?.total,
          current: search?.page,
          pageSize: search?.page_size,
        }}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
      {isVisible ? (
        <Modal
          open={isVisible}
          footer={null}
          title={`设置${selectJob.name}权限`}
          onCancel={() => setIsVisible(false)}
        >
          <SelectPower
            currentRoleId={c?.roleData?.id}
            selectJob={selectJob}
            platform_role={platform_role}
            onSave={() => setIsVisible(false)}
          />
        </Modal>
      ) : null}
    </div>
  );
};
export default App;
