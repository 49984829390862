/**
 * 获取自己公司的角色列表 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      page: 1
 *      page_size: 10
 *  }
 *  响应：
 *  {
 *      id： 角色编号
 *      name: 角色名称
 *      company_id: 角色所属公司
 *      create_at: 创建时间
 *      updated_at: 更新时间
 *  }
 */
export const roleList = "/merchant/client/api/permission/role/list";

/**
 * 添加角色 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      name: 角色名称
 *  }
 *  响应：
 *  {
 *      id： 角色编号
 *      name: 角色名称
 *      company_id: 角色所属公司
 *      create_at: 创建时间
 *      updated_at: 更新时间
 *  }
 */
export const roleCreate = "/merchant/client/api/permission/role/create";

/**
 * 修改角色 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id: 角色编号
 *      name: 角色名称
 *      company_id: 角色所属公司
 *  }
 *  响应：ok
 */
export const roleUpdate = "/merchant/client/api/permission/role/update";

/**
 * 角色删除 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id: 角色编号
 *      name: 角色名称
 *      company_id: 角色所属公司
 *  }
 *  响应：ok
 */
export const roleDelete = "/merchant/client/api/permission/role/delete";

/**
 * 获取角色权限  post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      role_id: 角色编号
 *  }
 *  响应：
 *  {
 *      id： 流水号
 *      role_id: 角色编号
 *      content: 角色权限json
 *      create_at: 创建时间
 *      updated_at: 更新时间
 *  }
 */
export const rolePermission =
  "/merchant/client/api/permission/role_permission/role_permission_info";

/**
 * 创建角色权限 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id: 流水号
 *      role_id: 角色编号
 *      content: 角色权限json
 *  }
 *  响应：
 *  {
 *      id： 流水号
 *      role_id: 角色编号
 *      content: 角色权限json
 *      create_at: 创建时间
 *      updated_at: 更新时间
 *  }
 */
export const rolePermissionCrete =
  "/merchant/client/api/permission/role_permission/create";

/**
 * 修改角色权限数据 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id: 流水号
 *      role_id: 角色编号
 *      content: 角色权限json
 *  }
 *  响应：
 *  {
 *      id： 流水号
 *      role_id: 角色编号
 *      content: 角色权限json
 *      create_at: 创建时间
 *      updated_at: 更新时间
 *  }
 */
export const rolePermissionUpdate =
  "/merchant/client/api/permission/role_permission/update";

/**
 * 删除角色权限数据 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id: 流水号
 *      role_id: 角色编号
 *  }
 *  响应：ok
 */
export const rolePermissionDelete =
  "/merchant/client/api/permission/role_permission/delete";

/**
 * 添加用户 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      phone: 用户电话
 *      password: 用户密码
 *      name: 用户名称
 *      avatar: 用户头像
 *      role: 用户角色
 *      computer_tag: 用户电脑唯一码
 *  }
 *  响应：
 *  {
 *
 *      id：
 *      phone：用户电话
 *      password： 用户密码
 *      name： 用户名称
 *      avatar： 用户头像
 *      status： 状态 1 启用  2 禁用
 *      role： 角色编号
 *      company_id： 公司编号
 *      company_name： 公司名称
 *      created_at： 创建时间
 *      updated_at：
 *      computer_tag： 用户电脑唯一码
 *      is_master： 1 主帐号  2 子帐号
 *  }
 */
export const userCreate = "/merchant/client/api/user/create";

/**
 * 设置用户权限 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      id:
 *      role: 角色编号
 *  }
 *  响应：ok
 */

export const userPermission = "/merchant/client/api/user/set_account";

/**
 * 获取用户列表 post
 *  请求：
 *  {
 *      Authorization: token;
 *      from:  pc 客户端 admin 管理后端
 *      page: 1
 *      page_size: 10
 *  }
 *  响应：ok
 */
export const userList = "/merchant/client/api/user/list";

// 公司 =================================================================
/**
 * 创建公司
 *
 */
export const companyCreate = "/merchant/client/api/company/create";

/**
 * 修改公司
 */
export const companyUpdate = "/merchant/client/api/company/update";

/**
 * 获取公司
 */
export const companyList = "/merchant/client/api/company/list";

/**
 * 公司详情
 */
export const companyInfo = "/merchant/client/api/company/get_info";

// 商户主账号 =================================================================
/**
 * 获取主账号
 *
 */
export const masterAccountList =
  "/merchant/client/api/company/master_account_list";

/**
 * 创建主账号
 *
 */
export const masterAccountCreate =
  "/merchant/client/api/company/master_account/create";
