import React from 'react';
import styles from './index.module.css';

const Index = (props) => {
  const { label, onChange, value } = props;
  const childProps = {
    onChange,
    value,
    bordered: false,
  };
  return (
    <span className={styles.wraper}>
      <span style={{ color: '#989898', flexShrink: 0 }}>{label}</span>
      {React.cloneElement(props.children, childProps)}
    </span>
  );
};

export default Index;
