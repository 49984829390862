import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Space, Table, Modal, Image, Select, message } from "antd";

import CreateUser from "./createUser";

import http from "../../../../lib/http";
import * as apis from "../../../../lib/account";
import { jobContext } from "../../../../components/context/job";

import styles from "./index.module.css";


function Com() {
    const j = useContext(jobContext);
    const [job, setJob] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectUser, setSelectUser] = useState(null);
    const [operaType, setOperaType] = useState("add");
    const [showUserCreatePop, setShowUserCreatePop] = useState(false);
    const [dataSource, setDataSource] = useState({
        page: 1,
        page_size: 10,
        total: 0,
        list: [],
    });

    const getJob = async () => {
        const data = await http.post(apis.roleList, { page: 1, pageSize: 1000 });
        if (data?.list) {
            setJob(
                data.list.map((item) => {
                    item.key = item.id;
                    return item;
                })
            );
        }
    };


    useEffect(() => { getJob(); }, [j.jobChange])

    const onSelect = async (msg, role) => {
        await http.post(apis.userPermission, { id: msg.id, account_type: msg.account_type, role, status: Number(msg.status), computer_tag: msg.computer_tag, avatar: msg.avatar, phone: msg.phone, name: msg.name });
        getUsers();
        message.success('设置成功')
    }

    const columns = useMemo(() => {
        return [
            {
                title: "账号姓名",
                dataIndex: "name",
                key: "name",
                width: "100px",
            },
            {
                title: "头像",
                dataIndex: "avatar",
                key: "avatar",
                width: "100px",
                render: (text) => <Image height={32} src={text} />,
            },
            {
                title: "公司名称",
                dataIndex: "company_name",
                width: "100px",
                key: "company_name",
            },
            {
                title: "职位",
                dataIndex: "role",
                width: "150px",
                key: "role",
                render: (text, record) =>
                // job.find(item => item.id === text).name
                (
                    <Select defaultValue={text} value={text} onSelect={(role) => { onSelect(record, role) }} style={{ width: '130px' }}>
                        {job.map((item) => {
                            return (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                )
            },
            {
                title: "账户",
                dataIndex: "phone",
                key: "phone",
                width: "100px",
            },
            {
                title: "入职时间",
                key: "created_at",
                width: "200px",
                dataIndex: "created_at",
            },
            {
                title: "操作",
                key: "action",
                width: "150px",
                render: (_, record) => (
                    <Space size="middle" onClick={() => {

                        updateUserClick(record)
                    }}>
                        <a>编辑</a>
                    </Space>
                ),
            },
        ]
    }, [job]);


    // 添加账号
    const addUserClick = () => {
        setSelectUser(null)
        setOperaType("add");
        setShowUserCreatePop(true);
    };

    // 编辑账号
    const updateUserClick = (user) => {
        setSelectUser(user);
        setOperaType("update");
        setShowUserCreatePop(true);
    };

    // 表格页数改变的时候
    const pageSearch = async (page, page_size) => {
        getUsers({ page: page, page_size: page_size });
    };

    // 加载数据
    const getUsers = async (pageConfig = { page: 1, page_size: 10 }) => {
        setLoading(true);
        const data = await http.post(apis.userList, pageConfig);
        if (data?.list) {
            data.list.map((item) => {
                item.key = item.id;
            });
            setDataSource(data);
            setLoading(false);
        }
    };

    // 编辑或者添加账号
    const userOpera = async (data) => {
        if (operaType === "add") {
            await http.post(apis.userCreate, { ...data, account_type: Number(data.account_type), status: Number(data.status) });
            getUsers();
            setShowUserCreatePop(false)
        } else {
            await http.post(apis.userPermission, { ...data, id: selectUser.id, account_type: Number(data.account_type), status: Number(data.status) });
            getUsers();
            setShowUserCreatePop(false)

        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className={styles.userTab}>
            {/* <QueryFrom /> */}

            <div className={styles.userTableDesc}>
                {/* <p>
                    在职账号-共30人
                </p> */}

                <div>
                    <Button type="primary" htmlType="submit" onClick={addUserClick}>
                        新增账号
                    </Button>
                </div>
            </div>

            <Table
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: pageSearch,
                    total: dataSource?.total,
                    current: dataSource?.page,
                    pageSize: dataSource?.page_size,
                }}
                loading={loading}
                style={{ minWidth: "650px" }}
                scroll={{ y: 500 }}
                columns={columns}
                dataSource={dataSource.list}
            />

            {showUserCreatePop ? (
                <Modal
                    title={`${operaType === "add" ? "新增" : "编辑"} 账号`}
                    footer={null}
                    open={showUserCreatePop}
                    onCancel={() => setShowUserCreatePop(false)}
                >
                    <CreateUser selectUser={selectUser} onSave={userOpera} />
                </Modal>
            ) : null}
        </div>
    );
}

export default Com;
