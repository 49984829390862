import { Button, Form, Input, Alert, Drawer, Select, Space, Table, Tooltip, message } from "antd"
import { cloneDeep } from "lodash";
import { useEffect, useImperativeHandle, useState } from 'react';
import Upload,{handleValue} from '../../components/upload'

export const maps = [
    {
        "label": "法线贴图（normalMap）",
        "value":"normalMap"
    },
    {
        "label": "光照贴图（aoMap）",
        "value":"aoMap"
    },
    {
        "label": "灰度贴图（alphaMap）",
        "value":"alphaMap"
    },
    {
        "label": "粗糙度贴图（roughnessMap）",
        "value":"roughnessMap"
    },
    {
        "label": "金属度贴图（metalnessMap）",
        "value":"metalnessMap"
    },
    {
        "label": "位移贴图（displacementMap）",
        "value":"displacementMap"
    },
    {
        "label": "发光贴图（emissiveMap）",
        "value":"emissiveMap"
    }
]

const mapDescription = {
    "normalMap": "法线贴图，一般都是蓝色的图，RGB值会影响每个像素片段的表面法线，并改变颜色的点亮方式。法线贴图不会改变表面的实际形状，只会改变光照。如果材料有一个使用左手惯例的法线映射，normalScale的y分量应该为负，以补偿不同的用手性",
    "aoMap":"模拟全局环境光照效果的贴图",
    "alphaMap":"控制整个表面的不透明度(黑色:完全透明;白色:完全不透明)",
    "roughnessMap":"",
    "metalnessMap":"这个纹理的蓝色通道用于改变材质的金属度",
    "displacementMap":"位移图影响网格顶点的位置。与其他只影响材质明暗的贴图不同，位移顶点可以投射阴影，阻挡其他物体，或者充当真正的几何图形。位移纹理是一个图像，其中每个像素的值(白色是最高的)被映射到网格的顶点，并重新定位",
    "emissiveMap": "发光贴图颜色由发光颜色和发光强度调节。如果你有一个发光贴图，一定要将发光颜色设置为黑色以外的颜色"
}

const MapAdd = (props) => {
    const { value, onChange, addBtnDisabled} = props
    return (
        <Space>
            <Select value={value} onChange={onChange} style={{width: 330}} placeholder="选择添加一个贴图" allowClear options={maps} />
            <Button disabled={addBtnDisabled} type="primary" onClick={props.add}>添加</Button>
        </Space>
    )
}

const Maps = (props) => {
    const { value = [], onChange} = props

    const columns = [
        {
            title: 'key',
            dataIndex: 'map',
            key: 'map',
            hidden: true
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (v) => (
                <Tooltip title={v}>
                    <div style={{
                        maxWidth: 80,
                        height: 18,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>{v}</div>
                </Tooltip>
            )
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            render: (v) => (
                <Tooltip title={v}>
                    <div style={{
                        maxWidth: 80,
                        height: 18,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>{v}</div>
                </Tooltip>
            )
        },
        {
            title: '贴图',
            dataIndex: 'url',
            key: 'url',
            render: (v,r) => (
                <div style={{maxWidth: 120}}>
                    <Upload value={handleValue(v)} onChange={(j)=>{
                        let cpV = cloneDeep(value)
                        let index = cpV.findIndex(i => i.map === r.map)
                        if(j && j.length !== 0){
                            cpV[index].url = j[0].url
                        }else{
                            cpV[index].url = ""
                        }
                        onChange(cpV)
                    }} />
                </div>
            )
        },
        {
            title: '操作',
            dataIndex: 'a',
            key: 'a',
            render: (v,r) => (
                <>
                    <Button danger type="link" style={{padding: 0}} onClick={()=>{
                        let cpV = cloneDeep(value)
                        let index = cpV.findIndex(i => i.map === r.map)
                        cpV.splice(index, 1)
                        onChange(cpV)
                    }}>删除</Button>
                </>
            )
        }
    ]

    return (
        <Table rowKey={"map"} dataSource={value} pagination={false} columns={columns} />
    )
}

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [curIndex, setCurIndex] = useState({})
    const [codes, setCodes] = useState([])


    const map = Form.useWatch("map",form)

    useImperativeHandle(props.funcs, () => ({
        setIsModalOpen,
        resetFields: form.resetFields,
        setFieldsValue: (vals) => {
            form.setFieldsValue(vals)
        },
        setCurIndex,
        setCodes
    }));

    const submit = async () => {
        try {
            const data = await form.validateFields()
            if(data.maps && data.maps.length !== 0){
                for(let i=0;i<data.maps.length;i++){
                    if(!data.maps[i].url){
                        message.error("请上传贴图")
                        return
                    }
                }
            }
            const codes_info = []
            for(let i=0;i<codes.length;i++){
                let code = codes[i]
                codes_info.push({
                    code,
                    ...data
                })
            }
            data.codes_info = codes_info
            if(props.onChange){
                const isSuccess = await props.onChange(data,curIndex)
                if(isSuccess){
                    setIsModalOpen(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    //添加贴图
    const onAddMap = () => {
        if(!map){
            return
        }
        const cpMaps = cloneDeep(form.getFieldValue("maps") || [])
        let f = cpMaps.find(i => i.map === map)
        if(f){
            return
        }
        cpMaps.push({
            map,
            name: maps.find(i => i.value === map).label,
            description: mapDescription[map]
        })
        form.setFieldValue("maps",cpMaps)
    }

    const titleTxt = () => {
        if(curIndex.length == 1 && curIndex[0] == -1){
            return '添加材质'
        }else if(curIndex.length == 1 && curIndex[0] != -1){
            return '更新材质'
        }else if(curIndex.length == 2){
            return '单个部位更新材质'
        }
    }

    return (
        <>
            <Drawer 
                placement="right"
                forceRender
                open={isModalOpen} 
                onClose={() => {
                    if(loading) return 
                    setIsModalOpen(false)
                }}
                title={titleTxt()}
                width="50vw"
                footer={
                    <div style={{textAlign: "right"}}>
                        <Space>
                            <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
                            <Button loading={loading} type="primary" onClick={submit}>确定</Button>
                        </Space>
                    </div>
                }
            >
                <Form form={form} autoComplete="off" labelAlign="right" labelCol={{span: 2}} style={{marginLeft: -10}}>
                    <Form.Item name="name" label="颜色" rules={[{required: true, message: "请填写"}]}>
                        <Input placeholder="请输入，例如白色" />
                    </Form.Item>
                    <Form.Item initialValue={"#FFFFFF"} name="color" label="色值" rules={[{required: true, message: "请填写"}]}>
                        <Input type="color" />
                    </Form.Item>
                    <Form.Item name="map" label="贴图" >
                        <MapAdd addBtnDisabled={!map} placeholder="请选择一个贴图" allowClear options={maps} add={onAddMap} />
                    </Form.Item>
                    {
                        mapDescription[map] && <Form.Item>
                            <Alert message={mapDescription[map]} type="info" showIcon />
                        </Form.Item>
                    }
                    <Form.Item name="maps" >
                        <Maps />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default Index