import React, { useContext, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message } from 'antd';
import bg from '../../static/img/bg.png';
import shadow from '../../static/img/aiwrap.png';
import * as apis from '../../lib/api';
import http from '../../lib/http';
import { useNavigate } from 'react-router-dom';
import { globalContext } from '../../components/context';
import { uniqueId } from '../../utils/index';

const Index = () => {
  const c = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const agree = Form.useWatch('agree', form);
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await http.post(apis.LOGIN, {
        ...values,
        computer_tag: uniqueId,
      });
      localStorage.setItem('token', data?.token);
      let txt = await c.onLoad();
      if (txt) {
        setLoading(false);
        message.error(txt);
        return;
      }
      setTimeout(() => {
        navigate('/');
      }, 0);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: '#2d3a4b',
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100vw 100vh',
          height: '100vh',
        }}
      >
        <div
          style={{
            width: 420,
            height: 420,
            margin: 'auto',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
          }}
        >
          <div style={{ width: '80%', margin: '0 auto', position: 'relative' }}>
            <img
              src={shadow}
              style={{
                position: 'absolute',
                top: 58,
                zIndex: 1,
                left: -60,
              }}
              alt=""
            />
            <div
              style={{
                margin: '55px 0 0 -58px',
                padding: '18px 10px 18px 60px',
                background: '#189F92',
                position: 'relative',
                color: '#fff',
                fontSize: 16,
              }}
            >
              {process.env.REACT_APP_NAME}
            </div>
            <div style={{ height: 55 }}></div>
            <Form
              autoComplete="off"
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '请输入用户名',
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="用户名"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="密码"
                  size="large"
                />
              </Form.Item>
              <Form.Item name="agree" valuePropName="checked">
                <Checkbox>
                  <span style={{ color: '#333333', fontSize: 12 }}>
                    登录即代表同意
                    <span
                      style={{ cursor: 'pointer', color: 'rgb(0,100,250)' }}
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_PC_URL}/xieyi`)
                      }
                    >
                      《用户隐私协议》
                    </span>
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={!agree}
                  loading={loading}
                  style={{
                    width: '100%',
                  }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {loading ? '登录中' : '登录'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
