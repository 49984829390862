import {
  Button,
  Form,
  Input,
  Slider,
  message,
  Modal,
  Cascader,
  Col,
  InputNumber,
  Row,
} from 'antd';
import { useImperativeHandle, useState, useContext } from 'react';
import { globalContext } from '../../../components/context';
import Upload from '../../../components/upload';
import * as apis from '../../../lib/api';
import http from '../../../lib/http';
import FabricParam from '../../../components/fabric_param';
import * as THREE from 'three';
const marks = {
  '-100': '-100%',
  100: '100%',
};
const formatter = (value) => `${value}%`;
const Index = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fieldsValue, asetFieldsValue] = useState({});
  const { ossClient } = useContext(globalContext);
  useImperativeHandle(props.funcs, () => ({
    setIsModalOpen,
    resetFields: form.resetFields,
    setFieldsValue: (vals) => {
      if (!Object.keys(vals).includes('lightness'))
        vals = {
          ...vals,
          saturation: 0,
          lightness: 0,
          temperature: 0,
          contrast: 0,
        };

      asetFieldsValue(vals);
      form.setFieldsValue(vals);
    },
  }));

  const dataURLtoBlob = (dataURL) => {
    var byteString = atob(dataURL.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  };
  const imgUpload = async (fileUrl) => {
    let filterValue = `saturate(${
      (fieldsValue.saturation + 100) / 100
    }) brightness(${(fieldsValue.lightness + 100) / 100}) contrast(${
      (fieldsValue.contrast + 100) / 100
    }) hue-rotate(${fieldsValue.temperature}deg)`;
    const img = new window.Image();
    img.src = img.src = process.env.REACT_APP_OSS_URL + fileUrl;
    img.setAttribute('crossOrigin', 'Anonymous');
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      ctx.filter = filterValue;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      var blob = dataURLtoBlob(dataURL);
      ossClient
        .put(fileUrl.split('.')[0] + 'lvjing.' + fileUrl.split('.')[1], blob)
        .then((res) => {
          console.log(res);
        });
    };
  };
  const submit = async () => {
    try {
      const data = await form.validateFields();
      const maps = [];
      if (data.map && data.map.length !== 0) {
        const urls = [];
        for (let i = 0; i < data.map.length; i++) {
          let fileUrl = data.map[i].url;
          // urls.push(data.map[i].url);
          imgUpload(fileUrl);
          urls.push(fileUrl.split('.')[0] + 'lvjing.' + fileUrl.split('.')[1]);
        }
        maps.push({
          map: 'map',
          url: urls.join(','),
        });
      }
      if (!fieldsValue.id) {
        setLoading(true);
        try {
          await http.post(apis.FABRIC_CREATE, {
            name: data.name,
            maps: JSON.stringify(maps),
            category_id: data.category_id[0] ? data.category_id[0] : 0,
            second_category_id: data.category_id[1] ? data.category_id[1] : 0,
            three_category_id: data.category_id[2] ? data.category_id[2] : 0,
            param: JSON.stringify(data.param),
            code: data.code,
            element: data.element,
            remark: data.remark,
            tp: 1,
            saturation:
              fieldsValue?.saturation === undefined
                ? 0
                : fieldsValue.saturation,
            lightness:
              fieldsValue?.lightness === undefined ? 0 : fieldsValue.lightness,
            temperature:
              fieldsValue?.temperature === undefined
                ? 0
                : fieldsValue.temperature,
            contrast:
              fieldsValue?.contrast === undefined ? 0 : fieldsValue.contrast,
          });
          setIsModalOpen(false);
          message.success('新建成功');
          props.onRefresh && props.onRefresh(1);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      } else {
        setLoading(true);
        try {
          await http.post(apis.FABRIC_UPDATE, {
            id: fieldsValue.id,
            name: data.name,
            maps: JSON.stringify(maps),
            category_id: data.category_id[0] ? data.category_id[0] : 0,
            second_category_id: data.category_id[1] ? data.category_id[1] : 0,
            three_category_id: data.category_id[2] ? data.category_id[2] : 0,
            param: JSON.stringify(data.param),
            code: data.code,
            element: data.element,
            remark: data.remark,
            tp: 1,
            saturation:
              fieldsValue?.saturation === undefined
                ? 0
                : fieldsValue.saturation,
            lightness:
              fieldsValue?.lightness === undefined ? 0 : fieldsValue.lightness,
            temperature:
              fieldsValue?.temperature === undefined
                ? 0
                : fieldsValue.temperature,
            contrast:
              fieldsValue?.contrast === undefined ? 0 : fieldsValue.contrast,
          });
          setIsModalOpen(false);
          message.success('更新成功');
          props.onRefresh && props.onRefresh();
        } catch (error) {}
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isModalOpen}
        width={500}
        onCancel={() => {
          if (loading) return;
          setIsModalOpen(false);
        }}
        title={!fieldsValue.id ? '新增' : '更新'}
        footer={
          <>
            <Button loading={loading} onClick={() => setIsModalOpen(false)}>
              取消
            </Button>
            <Button loading={loading} type="primary" onClick={submit}>
              确定
            </Button>
          </>
        }
      >
        <Form
          form={form}
          autoComplete="off"
          labelAlign="right"
          style={{
            marginLeft: -10,
            height: '70vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入' }]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item
            name="category_id"
            label="所属分类"
            rules={[{ required: true, message: '请选择' }]}
          >
            <Cascader
              style={{ width: '100%' }}
              allowClear
              options={props?.category}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item name="code" label="编号">
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item name="element" label="成分">
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item name="remark" label="备注">
            <Input placeholder="请填写" />
          </Form.Item>
          <Form.Item
            name="param"
            initialValue={{
              transparent: false,
              repeatNum: 26,
              repeatNumY: 26,
              repeat: true,
            }}
          >
            <FabricParam />
          </Form.Item>
          {/* <Form.Item name="cover" label="封面图" rules={[{required: true, message: "请上传"}]}>
                        <Upload />
                    </Form.Item> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              name="map"
              label="颜色贴图"
              rules={[{ required: true, message: '请上传颜色贴图！' }]}
              style={{ padding: '60px 0' }}
            >
              <Upload
                // maxCount={!fieldsValue.id ? 20 : 1}
                maxCount={1}
                multiple={!fieldsValue.id}
                lightness={fieldsValue.lightness}
                saturation={fieldsValue.saturation}
                colorTemperature={fieldsValue.temperature}
                contrast={fieldsValue.contrast}
                setToning={1}
              />
            </Form.Item>
          </div>
          <Form.Item name="saturation" label="饱和度">
            <Row>
              <Col span={12}>
                <Slider
                  marks={marks}
                  min={-100}
                  max={100}
                  step={1}
                  value={fieldsValue.saturation}
                  tooltip={{ formatter }}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.saturation = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={-100}
                  max={100}
                  step={1}
                  style={{ margin: '0 16px' }}
                  value={fieldsValue.saturation}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.saturation = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="lightness" label="亮度">
            <Row>
              <Col span={12}>
                <Slider
                  tooltip={{ formatter }}
                  marks={marks}
                  min={-100}
                  max={100}
                  step={1}
                  value={fieldsValue.lightness}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.lightness = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={-100}
                  max={100}
                  step={1}
                  style={{ margin: '0 16px' }}
                  value={fieldsValue.lightness}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.lightness = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="temperature" label="色温">
            <Row>
              <Col span={12}>
                <Slider
                  tooltip={{ formatter }}
                  marks={marks}
                  min={-100}
                  max={100}
                  step={1}
                  value={fieldsValue.temperature}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.temperature = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={-100}
                  max={100}
                  step={1}
                  style={{ margin: '0 16px' }}
                  value={fieldsValue.temperature}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.temperature = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="contrast" label="对比度">
            <Row>
              <Col span={12}>
                <Slider
                  tooltip={{ formatter }}
                  marks={marks}
                  min={-100}
                  max={100}
                  step={1}
                  value={fieldsValue.contrast}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.contrast = value;
                    asetFieldsValue(obj);
                  }}
                />{' '}
              </Col>
              <Col span={4}>
                <InputNumber
                  min={-100}
                  max={100}
                  step={1}
                  style={{ margin: '0 16px' }}
                  value={fieldsValue.contrast}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(fieldsValue));
                    obj.contrast = value;
                    asetFieldsValue(obj);
                  }}
                />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Index;
