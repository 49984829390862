import { Input, Modal, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';



export default function CategoryModal({
  propModalInfo,
  propIsVisible,
  propOnOk,
  propOnCancel,
  status,
}) {
  const modalInfo = propModalInfo;
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(modalInfo?.content ?? '');
  }, [modalInfo]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onOk = () => {
    if (!value) {
      message.error('请输入名称');
      return false;
    }
    if (propOnOk) {
      const newModalInfo = Object.assign({}, modalInfo);
      newModalInfo.name = value;
      propOnOk(newModalInfo);
      setValue('');
    }
  };

  const onCancel = () => {
    if (propOnCancel) {
      propOnCancel();
      setValue('');
    }
  };

  return (
    <Modal
      open={propIsVisible}
      title={modalInfo?.title}
      onOk={onOk}
      onCancel={onCancel}
      okText="确认"
      cancelText="取消"
    >
      <Input
          style={{width:'100%'}}
          placeholder={modalInfo?.placeholder ?? "请输入名称"}
          value={value}
          onChange={onChange}
          maxLength={50}
        />
    </Modal>
  );
}
