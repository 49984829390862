import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd"
import { cloneDeep } from "lodash";
import { useImperativeHandle, useState } from 'react';
import Upload from '../../components/upload'
import * as apis from '../../lib/api'
import http from '../../lib/http'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fieldsValue, asetFieldsValue] = useState({})

    useImperativeHandle(props.funcs, () => ({
        setIsModalOpen,
        resetFields: form.resetFields,
        setFieldsValue: (vals) => {
            asetFieldsValue(vals)
            form.setFieldsValue(vals)
        }
    }));

    const submit = async () => {
        try {
            const data = await form.validateFields()
            const cpData = cloneDeep(data)
            cpData.cover = data.cover[0].url
            if(cpData.tags && cpData.tags.length !== 0){
                cpData.tags = data?.tags?.join()
            }else{
                delete cpData.tags
            }
            cpData.price_str = `${cpData.price_str}`
            if(cpData.imgs && cpData.imgs.length !== 0){
                cpData.imgs = JSON.stringify(cpData.imgs)
            }else{
                delete cpData.imgs
            }
            if(fieldsValue.id){
                cpData.id = fieldsValue.id
            }
            if(!fieldsValue.id){
                setLoading(true)
                try {
                    await http.post(apis.PRODUCT_CREATE, cpData)
                    setIsModalOpen(false)
                    message.success("新建成功")
                    props.onRefresh && props.onRefresh(1)
                } catch (error) {
                    
                }
                setLoading(false)
            }else{
                setLoading(true)
                try {
                    await http.post(apis.PRODUCT_UPDATE,cpData)
                    setIsModalOpen(false)
                    message.success("更新成功")
                    props.onRefresh && props.onRefresh()
                } catch (error) {
                    
                }
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <Modal 
                forceRender
                open={isModalOpen} 
                onCancel={() => {
                    if(loading) return 
                    setIsModalOpen(false)
                }}
                title={!fieldsValue.id ? '新建产品':'更新产品'}
                footer={
                    <>
                        <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
                        <Button loading={loading} type="primary" onClick={submit}>确定</Button>
                    </>
                }
            >
                <Form form={form} autoComplete="off" labelAlign="right" labelCol={{span: 4}} style={{marginLeft: -10}}>
                    <Form.Item name="name" label="名称" rules={[{required: true, message: "请上传"}]}>
                        <Input allowClear placeholder="请输入" />
                    </Form.Item>
                    <Form.Item name="cover" label="封面图" rules={[{required: true, message: "请上传"}]}>
                        <Upload />
                    </Form.Item>
                    <Form.Item name="imgs" label="细节图">
                        <Upload maxCount={10} />
                    </Form.Item>
                    <Form.Item name="price_str" label="基础价" rules={[{required: true, message: "请填写"}]}>
                        <InputNumber precision={2} placeholder="请输入" min={0.01} />
                    </Form.Item>
                    <Form.Item name="category_id" label="所属分类" rules={[{required: true, message: "请选择"}]}>
                        <Select allowClear placeholder="请选择">
                            {
                                props?.category?.map(v => (
                                    <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="tags" label="标签">
                        <Select
                            allowClear
                            mode="tags"
                            placeholder="输入标签"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Index