import { Input, Form, QRCode, Button, Spin, message } from "antd";
import { useEffect, useState } from "react";
import Upload, { handleValue } from "../../components/upload";
import * as apis from "../../lib/api";
import http from "../../lib/http";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    console.log(123);
    setLoading(true);
    http.get(apis.INDEX_DATA).then((r) => {
      console.log(r);
      setLoading(false);
      if (r.config) {
        r.config = JSON.parse(r.config);
        r.config.bg_url = handleValue(r.config.bg_url);
        form.setFieldsValue(r.config);
      }
    });
  }, []);

  const save = async (values) => {
    setLoading(true);
    try {
      await http.post(apis.INDEX_DATA_UPDATE, {
        config: JSON.stringify({
          bg_url: values?.bg_url[0].url,
          bg_color: values?.bg_color,
        }),
      });
      message.success("保存成功");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Form
        onFinish={save}
        form={form}
        labelAlign="right"
        labelCol={{ span: 2 }}
      >
        <Form.Item
          initialValue={process.env.REACT_APP_H5_URL}
          name="link"
          label="访问链接"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          initialValue={process.env.REACT_APP_H5_URL}
          name="qrlink"
          label="二维码"
        >
          <QRCode size={370} />
        </Form.Item>
        <Form.Item
          name="bg_url"
          label="开屏图"
          rules={[{ required: true, message: "请上传" }]}
        >
          <Upload />
        </Form.Item>
        <Form.Item
          initialValue={"#FFFFFF"}
          name="bg_color"
          label="背景色"
          rules={[{ required: true, message: "请选择" }]}
        >
          <Input type="color" />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button htmlType="submit" type="primary">
            保存配置
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default Index;
