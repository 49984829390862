import { Button, Form, Input, message, Modal, Cascader, Select } from "antd"
import { useImperativeHandle, useState } from 'react';
import Upload from '../../components/upload'
import * as apis from '../../lib/api'
import http from '../../lib/http'
import FabricParam from '../../components/fabric_param'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [fieldsValue, asetFieldsValue] = useState({})

    useImperativeHandle(props.funcs, () => ({
        setIsModalOpen,
        resetFields: form.resetFields,
        setFieldsValue: (vals) => {
            asetFieldsValue(vals)
            form.setFieldsValue(vals)
        }
    }));

    const submit = async () => {
        try {
            const data = await form.validateFields()
            const maps = []
            if(!fieldsValue.id){
                setLoading(true)
                try {
                    await http.post(apis.PC_PATTERN_CREATE,{
                        name: data.name, 
                        path: data.path[0].url,
                        tags: data?.tags?.join(),
                        category_id: data.category_id[0] ? data.category_id[0]:0, 
                        second_category_id: data.category_id[1] ? data.category_id[1]:0, 
                        three_category_id: data.category_id[2] ? data.category_id[2]:0
                    })
                    setIsModalOpen(false)
                    message.success("新建成功")
                    props.onRefresh && props.onRefresh(1)
                } catch (error) {
                    
                }
                setLoading(false)
            }else{
                setLoading(true)
                try {
                    await http.post(apis.PC_PATTERN_UPDATE,{
                        id: fieldsValue.id,
                        name: data.name, 
                        path: data.path[0].url, 
                        tags: data?.tags?.join(),
                        category_id: data.category_id[0] ? data.category_id[0]:0, 
                        second_category_id: data.category_id[1] ? data.category_id[1]:0, 
                        three_category_id: data.category_id[2] ? data.category_id[2]:0
                    })
                    setIsModalOpen(false)
                    message.success("更新成功")
                    props.onRefresh && props.onRefresh()
                } catch (error) {
                    
                }
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <Modal 
                forceRender
                open={isModalOpen} 
                onCancel={() => {
                    if(loading) return 
                    setIsModalOpen(false)
                }}
                title={!fieldsValue.id ? '新增':'更新'}
                footer={
                    <>
                        <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
                        <Button loading={loading} type="primary" onClick={submit}>确定</Button>
                    </>
                }
            >
                <Form form={form} autoComplete="off" labelAlign="right" labelCol={{span: 4}} style={{marginLeft: -10}}>
                    <Form.Item name="name" label="名称">
                        <Input placeholder="请输入" />
                    </Form.Item>
                    <Form.Item name="tags" label="标签">
                        <Select
                            mode="tags"
                            placeholder="输入标签"
                        />
                    </Form.Item>
                    <Form.Item name="category_id" label="所属分类" rules={[{required: true, message: "请选择"}]}>
                        <Cascader style={{width: "100%"}} allowClear options={props?.category} placeholder="请选择" />
                    </Form.Item>
                    <Form.Item name="path" label="图案" rules={[{required: true, message: "请上传"}]}>
                        <Upload />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Index