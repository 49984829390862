import React, { useState } from 'react';

import Job from './job'
import Account from './account/index'

import { jobContext as JobContext } from "../../../components/context/job";

import styles from './index.module.css';

function Com() {
    // 控制在账号列表里请求角色请求的变量
    const [jobChange, setJobChange] = useState(Date.now())

    return (
        <JobContext.Provider
            value={{
                jobChange,
                setJobChange
            }}
        >
            <div className={styles.account}>
                <Job />
                <Account />
            </div>
        </JobContext.Provider>
    )
}

export default Com