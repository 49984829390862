import { Button, Form, Input, message, Modal, Cascader } from 'antd';
import { useImperativeHandle, useState } from 'react';
import Upload from '../../components/upload';
import * as apis from '../../lib/api';
import http from '../../lib/http';
import FabricParam from '../../components/fabric_param';

const Index = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fieldsValue, asetFieldsValue] = useState({});

  useImperativeHandle(props.funcs, () => ({
    setIsModalOpen,
    resetFields: form.resetFields,
    setFieldsValue: (vals) => {
      asetFieldsValue(vals);
      form.setFieldsValue(vals);
    },
  }));

  const submit = async () => {
    try {
      const data = await form.validateFields();
      const maps = [];
      if (data.map && data.map.length !== 0) {
        maps.push({
          map: 'map',
          url: data.map[0].url,
        });
      }
      if (data.normalMap && data.normalMap.length !== 0) {
        maps.push({
          map: 'normalMap',
          url: data.normalMap[0].url,
        });
      }
      if (data.specularMap && data.specularMap.length !== 0) {
        maps.push({
          map: 'specularMap',
          url: data.specularMap[0].url,
        });
      }
      if (data.aoMap && data.aoMap.length !== 0) {
        maps.push({
          map: 'aoMap',
          url: data.aoMap[0].url,
        });
      }
      if (data.displacementMap && data.displacementMap.length !== 0) {
        maps.push({
          map: 'displacementMap',
          url: data.displacementMap[0].url,
        });
      }

      if (data.roughnessMap && data.roughnessMap.length !== 0) {
        maps.push({
          map: 'roughnessMap',
          url: data.roughnessMap[0].url,
        });
      }

      if (data.metalnessMap && data.metalnessMap.length !== 0) {
        maps.push({
          map: 'metalnessMap',
          url: data.metalnessMap[0].url,
        });
      }

      if (!fieldsValue.id) {
        setLoading(true);
        try {
          await http.post(apis.FABRIC_CREATE, {
            name: data.name,
            assist: 1,
            cover: data.cover[0].url,
            maps: JSON.stringify(maps),
            category_id: data.category_id[0] ? data.category_id[0] : 0,
            second_category_id: data.category_id[1] ? data.category_id[1] : 0,
            three_category_id: data.category_id[2] ? data.category_id[2] : 0,
            param: JSON.stringify(data.param),
          });
          setIsModalOpen(false);
          message.success('新建成功');
          props.onRefresh && props.onRefresh(1);
        } catch (error) {}
        setLoading(false);
      } else {
        setLoading(true);
        try {
          await http.post(apis.FABRIC_UPDATE, {
            id: fieldsValue.id,
            name: data.name,
            cover: data.cover[0].url,
            maps: JSON.stringify(maps),
            category_id: data.category_id[0] ? data.category_id[0] : 0,
            second_category_id: data.category_id[1] ? data.category_id[1] : 0,
            three_category_id: data.category_id[2] ? data.category_id[2] : 0,
            param: JSON.stringify(data.param),
          });
          setIsModalOpen(false);
          message.success('更新成功');
          props.onRefresh && props.onRefresh();
        } catch (error) {}
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isModalOpen}
        onCancel={() => {
          if (loading) return;
          setIsModalOpen(false);
        }}
        title={!fieldsValue.id ? '新增' : '更新'}
        footer={
          <>
            <Button loading={loading} onClick={() => setIsModalOpen(false)}>
              取消
            </Button>
            <Button loading={loading} type="primary" onClick={submit}>
              确定
            </Button>
          </>
        }
      >
        <Form
          form={form}
          autoComplete="off"
          labelAlign="right"
          style={{ marginLeft: -10, height: '70vh', overflow: 'auto' }}
        >
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入' }]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item
            name="category_id"
            label="所属分类"
            rules={[{ required: true, message: '请选择' }]}
          >
            <Cascader
              style={{ width: '100%' }}
              allowClear
              options={props?.category}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item
            name="param"
            initialValue={{
              transparent: false,
              repeatNum: 26,
              repeatNumY: 26,
              repeat: true,
            }}
          >
            <FabricParam />
          </Form.Item>
          <Form.Item
            name="cover"
            label="封面图"
            rules={[{ required: true, message: '请上传' }]}
          >
            <Upload />
          </Form.Item>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              name="map"
              label="颜色贴图"
              rules={[{ required: true, message: '请上传' }]}
            >
              <Upload />
            </Form.Item>
            <Form.Item name="normalMap" label="法线贴图">
              <Upload />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item name="specularMap" label="高光贴图">
              <Upload />
            </Form.Item>
            <Form.Item name="aoMap" label="环境贴图">
              <Upload />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item name="displacementMap" label="置换贴图">
              <Upload />
            </Form.Item>
            <Form.Item name="roughnessMap" label="粗糙度贴图">
              <Upload />
            </Form.Item>
          </div>
          <Form.Item name="metalnessMap" label="金属光泽度贴图">
            <Upload />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Index;
