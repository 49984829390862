import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table, Row, Col, Input, Form, Button, Switch, Space, Divider, message, Modal } from 'antd'
import Prefix from '../../../components/prefix'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import * as apis from '../../../lib/api'
import http from '../../../lib/http'
import _ from 'lodash'
import Add from './add'
import { judgePermission } from "../../../utils";




const Index = () => {
    const [search, setSearch] = useState({ page: 1, page_size: 20 })
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [seleckeys, setSeleckeys] = useState([])


    const addRef = useRef()

    const loadData = async (s) => {
        try {
            setData(await http.post(apis.PRODUCT_CATEGORY_LIST, s))
            setSeleckeys([])
        } catch (error) {

        }
    }

    const onSearch = async (values) => {
        let cpSearch = { ...search }
        cpSearch.name = values?.name
        cpSearch.page = 1
        setLoading(true)
        await loadData(cpSearch)
        setLoading(false)
        setSearch(cpSearch)
    }

    const pageSearch = async (page, page_size) => {
        let cpSearch = { ...search }
        cpSearch.page_size = page_size
        cpSearch.page = page
        setLoading(true)
        await loadData(cpSearch)
        setLoading(false)
        setSearch(cpSearch)
    }

    const showChange = async (checked, v) => {
        const cpData = _.cloneDeep(data)
        const index = cpData?.list?.findIndex(m => m.id === v.id)
        if (index !== -1) {
            cpData.list[index].status = checked ? 1 : 2
            setLoading(true)
            try {
                await http.post(apis.PRODUCT_CATEGORY_SHOW_UPDATE, { id: v.id, status: cpData.list[index].status })
                setData(cpData)
            } catch (error) {

            }
            setLoading(false)
        }
    }

    const del = async (ids) => {
        setLoading(true)
        try {
            await http.post(apis.PRODUCT_CATEGORY_DELETE, { ids })
            message.success("操作成功")
            await loadData(search)
        } catch (error) {

        }
        setLoading(false)
    }

    const add = () => {
        addRef.current.setIsModalOpen(true)
        addRef.current.resetFields()
        addRef.current.setFieldsValue({})
    }

    const edit = (v) => {
        addRef.current.setIsModalOpen(true)
        addRef.current.resetFields()
        addRef.current.setFieldsValue({ id: v.id, name: v?.name, imgs: [{ url: v?.img_url, status: "done" }] })
    }

    const onRefresh = async (isInit) => {
        if (!isInit) {
            setLoading(true)
            await loadData(search)
            setLoading(false)
            return
        }
        let cpSearch = { ...search }
        cpSearch.page = 1
        setLoading(true)
        await loadData(cpSearch)
        setLoading(false)
        setSearch(cpSearch)
    }

    useEffect(() => {
        setLoading(true)
        loadData(search).then(r => {
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    const columns = useMemo(() => {
        const col = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: '名字',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '是否显示',
                dataIndex: 'status',
                key: 'status',
                render: (v, r) => (
                    <Switch checked={v === 1} onChange={(e) => showChange(e, r)} />
                )
            },
            {
                title: '操作',
                dataIndex: 'a',
                key: 'a',
                render: (v, r) => (
                    <>
                        {
                            judgePermission('productCategoryEdit') ? <Button loading={loading} type="link" onClick={() => edit(r)} style={{ padding: 0 }}>编辑</Button> : null
                        }

                        {
                            judgePermission('productCategoryDelete') ? <> <Divider type="vertical" />
                                <Button loading={loading} style={{ padding: 0 }} type="link" danger onClick={() => {
                                    Modal.confirm({
                                        title: "提示",
                                        content: <p>确定删除吗?</p>,
                                        onOk: async () => {
                                            await del([r.id])
                                        }
                                    })
                                }}>删除</Button></> : null
                        }

                    </>
                )
            }
        ]

        const findIsShow = col.findIndex(item => item.title === '是否显示')
        if (findIsShow !== -1 && !judgePermission('productCategoryIsShow')) {
            col.splice(findIsShow, 1)
        }

        return col;
    }, [])

    return (
        <div>
            <Add funcs={addRef} onRefresh={onRefresh} />
            <Form onFinish={onSearch}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item name="name">
                            <Prefix label="名称">
                                <Input allowClear placeholder="请输入" />
                            </Prefix>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item>
                            <Space>
                                {
                                    judgePermission('productCategoryFind') ? <Button loading={loading} type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button> : null
                                }
                                {
                                    judgePermission('productCategoryAdd') ? <Button onClick={add} loading={loading} ghost type="primary" icon={<PlusOutlined />}>新增分类</Button> : null
                                }
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {judgePermission('productCategoryDelete') ? <Space>
                <span>已选 {seleckeys?.length} 条</span>
                <Button danger disabled={seleckeys?.length === 0} onClick={() => {
                    Modal.confirm({
                        title: "提示",
                        content: <p>确定删除 <span style={{ color: 'red' }}>{seleckeys?.length}条</span> 数据吗?</p>,
                        onOk: async () => {
                            await del(seleckeys)
                        }
                    })
                }}>批量删除</Button>
            </Space> : null}

            <div style={{ height: 12 }}></div>
            <Table
                rowKey={'id'}
                dataSource={data?.list || []}
                loading={loading}
                columns={columns}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange: pageSearch,
                    total: data?.total,
                    current: data?.page,
                    pageSize: data?.page_size
                }}
                scroll={{
                    y: 'calc(100vh - 455px)'
                }}
                rowSelection={{
                    onChange: (keys) => {
                        setSeleckeys(keys)
                    },
                    selectedRowKeys: seleckeys
                }}
            />
        </div>
    )
}

export default Index