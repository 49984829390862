import { Button, Form, Input, message, Modal, Typography } from "antd"
import { useImperativeHandle, useState } from 'react';
// import Upload from '../../components/upload'
import * as apis from '../../lib/api'
import http from '../../lib/http'

const Index = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [detailData, setDetailData] = useState({})
    const [loading, setLoading] = useState(false)

    const openModal = (record) => {
      console.log('record', record)
      setDetailData(record)
      setIsModalOpen(true)
    }

    const closeModal = () => {
      setLoading(false)
      setDetailData({})
      setIsModalOpen(false)
    }

    useImperativeHandle(props.funcs, () => ({
        openModal: (record) => openModal(record),
        closeModal
    }));

    const submit = async () => {
      const data = await form.validateFields()
      setLoading(true)
      try {
        await http.post(apis.fashionShowApplyApi,{
          design_id: detailData.id,
          // model: 1, 
          apply_context: data.apply_context, 
        })
        closeModal()
        props.onSuccess && props.onSuccess();
        message.success("提交成功! 等待审批中...")
      } finally {
        setLoading(false)
      }
    }


    return (
      <>
        <Modal 
          // forceRender
          open={isModalOpen} 
          onCancel={() => {
            if(loading) return 
            setIsModalOpen(false)
          }}
          title='申请走秀动画'
          footer={
            <>
              <Button loading={loading} onClick={() => setIsModalOpen(false)}>取消</Button>
              <Button loading={loading} type="primary" onClick={submit}>确定</Button>
            </>
          }
        >
          <Form form={form} autoComplete="off" labelAlign="right" labelCol={{span: 4}} style={{marginLeft: -10}}>
            <Form.Item style={{ marginTop: '24px' }} name="name" label="名字">
            <Typography.Text>{detailData.name}</Typography.Text>
            </Form.Item>
            <Form.Item name="apply_context" rules={[{required: true,message: '请填写走秀动画备注信息!',}]} label="申请备注">
              <Input.TextArea placeholder="请输入" />
            </Form.Item>
            
            {/* <Form.Item name="category_id" label="所属分类" rules={[{required: true, message: "请选择"}]}>
                <Cascader style={{width: "100%"}} allowClear options={props?.category} placeholder="请选择" />
            </Form.Item> */}
            {/* <Form.Item name="path" label="图案" rules={[{required: true, message: "请上传"}]}>
                <Upload />
            </Form.Item> */}
          </Form>
        </Modal>
      </>
    )
}

export default Index